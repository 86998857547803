import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../constants/adaptivity';

export const useResponsiveFlags = () => {
  const isLargeDesktop = useMediaQuery({
    minWidth: Breakpoints.largeDesktop + 1,
  });
  const isSmallDesktop = useMediaQuery({
    minWidth: Breakpoints.smallDesktop + 1,
    maxWidth: Breakpoints.largeDesktop,
  });
  const isLargeTablet = useMediaQuery({
    minWidth: Breakpoints.largeTablet + 1,
    maxWidth: Breakpoints.smallDesktop,
  });
  const isSmallTablet = useMediaQuery({
    minWidth: Breakpoints.smallTablet + 1,
    maxWidth: Breakpoints.largeTablet,
  });
  const isLargeMobile = useMediaQuery({
    minWidth: Breakpoints.largeMobile + 1,
    maxWidth: Breakpoints.smallTablet,
  });
  const isSmallMobile = useMediaQuery({
    maxWidth: Breakpoints.largeMobile,
  });

  return {
    isLargeDesktop,
    isSmallDesktop,
    isLargeTablet,
    isSmallTablet,
    isLargeMobile,
    isSmallMobile,
    isMobile: isLargeMobile || isSmallMobile,
    isTablet: isLargeTablet || isSmallTablet,
    isDesktop: isLargeDesktop || isSmallDesktop,
  };
};
