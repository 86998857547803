import { ReactElement } from 'react';
import { ReactComponent as VK } from '../../../assets/vk.svg';
import { ReactComponent as Telegram } from '../../../assets/tg.svg';
import { ReactComponent as YouTube } from '../../../assets/youtube.svg';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Row from 'components/core/layout/Row';
import styles from './SubscribeItems.module.scss';
import Url from 'constants/urls';
import { useTranslation } from 'react-i18next';
import VKVideoIcon from 'assets/icons/VKVideoIcon';

const SubscribeItems = (): ReactElement => {
  const { t } = useTranslation();

  const renderSocialIcons = () => (
    <div className={styles.socialIcons}>
      <a href={Url.socials.VK} target='_blank' rel='noreferrer'>
        <VK />
      </a>
      <a href={Url.socials.TELEGRAM} target='_blank' rel='noreferrer'>
        <Telegram />
      </a>
      <a href={Url.socials.VK_VIDEO} target='_blank' rel='noreferrer'>
        <VKVideoIcon width='32' height='32' fill='white' />
      </a>
      <a href={Url.socials.YOUTUBE} target='_blank' rel='noreferrer'>
        <YouTube />
      </a>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet largeMobile>
        <Row
          justifyContent='space-between'
          alignItems='center'
          height='40px'
          gap='20px'
        >
          <>
            <span className={styles.text}>
              {t('footer.subscribeItemsText')}
            </span>
            {renderSocialIcons()}
          </>
        </Row>
      </ShowOn>
      <ShowOn smallMobile>
        <Row justifyContent='center' alignItems='center' height='25px'>
          {renderSocialIcons()}
        </Row>
      </ShowOn>
    </div>
  );
};

export default SubscribeItems;
