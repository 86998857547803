import { ReactElement, useRef } from 'react';
import styles from './HighlightCard.module.scss';

type HighlightCardProps = {
  src: string;
  caption: string;
  onClick?: () => void;
};

function HighlightCard({
  src,
  caption,
  onClick,
}: HighlightCardProps): ReactElement {
  return (
    <div className={styles.highlightCard}>
      <div className={styles.iframeContainer}>
        <iframe src={src} title='Video Content' allowFullScreen></iframe>
        <button onClick={onClick}></button>
      </div>
      <a title={caption} className={styles.caption}>
        {caption}
      </a>
    </div>
  );
}
export default HighlightCard;
