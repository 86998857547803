import styles from './YearVideoFilter.module.scss';
import { useEffect, useState } from 'react';
import { LOKOMOTIV_VK_VIDEO_OLDEST_VIDEO_YEAR } from '../../../constants/constants';
import Select, { SelectOption } from 'components/common/Select/Select';
import { getVkVideos } from 'api/api';

interface YearVideoFilterProps {
  onSelect: (optionsId: number) => void;
  value?: number;
}

const YearVideoFilter = ({ onSelect, value }: YearVideoFilterProps) => {
  const [options, setOptions] = useState<{ value: number; label: string }[]>(
    []
  );

  useEffect(() => {
    const getOptions = async () => {
      try {
        const response = await getVkVideos(0, 1);
        const latestDate = new Date(
          response.data[0].attributes.web_date * 1000
        );
        const latestYear = latestDate.getFullYear();
        const optionsList = [];

        for (
          let i = latestYear;
          i >= LOKOMOTIV_VK_VIDEO_OLDEST_VIDEO_YEAR;
          i--
        ) {
          optionsList.push({
            value: i,
            label: i.toString(),
          });
        }

        setOptions(optionsList);
        onSelect(latestYear);
      } catch (error) {
        setOptions([]);
        onSelect(new Date().getFullYear());
      }
    };

    getOptions();
  }, []);

  const onValueChange = (value: string) => {
    onSelect(Number(value));
  };

  function getSelectOptions(
    options: { value: number; label: string }[]
  ): SelectOption[] {
    return options.map((option) => {
      return {
        value: option.value.toString(),
        displayValue: option.value.toString(),
      };
    });
  }

  return value ? (
    <div className={styles.wrapper}>
      <div className={styles.customSelect}>
        <Select
          defaultValue={value.toString()}
          values={getSelectOptions(options)}
          onChange={onValueChange}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default YearVideoFilter;
