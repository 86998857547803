import { FC } from 'react';
import styles from './VideoPlayer.module.scss';

type VideoPlayerProps = {
  selectedVideo?: string;
};

const VideoPlayer: FC<VideoPlayerProps> = ({
  selectedVideo,
}: VideoPlayerProps) => {
  if (!selectedVideo || selectedVideo.length === 0) return <div></div>;

  return (
    <div className={styles.wrapper}>
      <iframe
        title='VK video player'
        src={selectedVideo}
        allow='encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
        allowFullScreen
        className={styles.player}
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
