import classNames from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getDefaultPlayerImage,
  getVideos,
  getVkVideosByName,
} from '../../../../api/api';
import { DEFAULT_IMAGE_NAME } from '../../../../constants/constants';
import { useResponsiveFlags } from '../../../../hooks/useResponsiveFlags';
import {
  CommonVideo,
  DefaultImageObject,
  VKVideosVideo,
  File,
} from '../../../../types/Types';
import VideoModal from '../../../VideoModal/VideoModal';
import ViewMoreButton from '../../../ViewMoreButton/ViewMoreButton';
import PlayerVideo from '../../../PlayerPage/PlayerVideo/PlayerVideo';
import styles from '../SubTab/SubTab.module.scss';
import {
  decodeHTMLEntities,
  previewOfVideo,
} from '../../../../helpers/helpers';

type VideosSubTabProps = {
  name?: string;
  surname?: string;
};

const VideosSubTab: FC<VideosSubTabProps> = ({ name, surname }) => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isMobile } = useResponsiveFlags();

  const ALBUM_PAGE_AMOUNT = !isDesktop ? 8 : 9;

  const [videoCountToShow, setVideoCountToShow] =
    useState<number>(ALBUM_PAGE_AMOUNT);
  const [strapiVideos, setStrapiVideos] = useState<File[]>([]);
  const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();
  const [vkVideos, setVkVideos] = useState<
    {
      id: number;
      json: VKVideosVideo;
      web_date: string;
      web_id: string;
    }[]
  >([]);
  const [selectedVideo, setSelectedVideo] = useState<
    { isVkVideo: boolean; source: string } | undefined
  >();

  const handleViewMoreButtonClick = () => {
    setVideoCountToShow(videoCountToShow + ALBUM_PAGE_AMOUNT);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
        setDefaultPhoto(photo.data[0]);
      } catch (e: any) {
        console.error(e);
      }
    };
    getData();
  }, []);

  const videosToDisplay = useMemo(() => {
    const topStrapiVideos = strapiVideos
      .slice(0, videoCountToShow)
      .map((video) => {
        return {
          isVkVideo: false,
          videoData: {
            id: video.id.toString(),
            url: video.url,
            title: decodeHTMLEntities(video.name),
            date: video.createdAt,
            previewUrl: video.previewUrl,
          },
        };
      });
    let topVideos: { isVkVideo: boolean; videoData: CommonVideo }[] = [];

    if (topStrapiVideos.length < videoCountToShow) {
      topVideos = vkVideos
        .slice(0, videoCountToShow - topStrapiVideos.length)
        .map((video) => {
          return {
            isVkVideo: true,
            videoData: {
              id: video.web_id,
              url: video.json.player,
              title: decodeHTMLEntities(video.json.title),
              date: new Date(Number(video.web_date) * 1000).toISOString(),
              previewUrl: previewOfVideo(video.json),
            },
          };
        });
    }

    return [...topStrapiVideos, ...topVideos] as {
      isVkVideo: boolean;
      videoData: CommonVideo;
    }[];
  }, [strapiVideos, videoCountToShow, vkVideos]);

  useEffect(() => {
    const getStrapiVideos = async () => {
      try {
        if (name && surname) {
          const videos = await getVideos(
            `${name.trim()} ${surname.trim()}`,
            -1
          );
          setStrapiVideos(videos);
        }
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };
    getStrapiVideos();
  }, [name, surname]);

  useEffect(() => {
    const getVkVideos = async () => {
      if (name && surname) {
        try {
          const result = await getVkVideosByName(name, surname);

          setVkVideos(result);
        } catch (error: any) {
          console.error(error?.response?.data?.error || error?.message);
        }
      }
    };
    getVkVideos();
  }, [name, surname]);

  const onVideoSelect = (source: string, isVkVideo: boolean) => {
    setSelectedVideo({
      source,
      isVkVideo,
    });
  };

  const onClose = () => {
    setSelectedVideo(undefined);
  };

  return (
    <>
      <div
        className={classNames(styles.photosGrid, {
          [styles.photosGridThreeColumns]: isDesktop,
          [styles.photosGridTwoColumns]: isTablet,
          [styles.photosGridOneColumn]: isMobile,
        })}
      >
        {videosToDisplay.length !== 0 ? (
          videosToDisplay.map((video, index) => (
            <PlayerVideo
              video={video.videoData}
              openVideo={onVideoSelect}
              isVkVideo={video.isVkVideo}
              index={index}
              key={video.videoData.id}
              defaultImage={defaultPhoto}
            />
          ))
        ) : (
          <div className={styles.noPhotos}>
            {t('playerPage.tabs.nothingHereYet')}
          </div>
        )}
      </div>
      {videosToDisplay.length <= videoCountToShow &&
      strapiVideos.length + vkVideos.length > videosToDisplay.length ? (
        <ViewMoreButton
          onClick={() => {
            handleViewMoreButtonClick();
          }}
        />
      ) : null}
      <VideoModal
        isVisible={!!selectedVideo}
        videoSource={selectedVideo ? selectedVideo.source : ''}
        close={onClose}
        isIFrame={selectedVideo?.isVkVideo}
      />
    </>
  );
};

export default VideosSubTab;
