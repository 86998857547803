import { getFoulsList } from 'api/api';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import ImageBgPageWrapper from 'components/common/ImageBgPageWrapper/ImageBgPageWrapper';
import VideoModal from 'components/VideoModal/VideoModal';
import InfoWrapper from 'components/Wrappers/InfoWrapper/InfoWrapper';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getGameById,
  getGameHighlights,
  getGameProtocol,
  getGameProtocolAdmin,
} from '../../../api/api';
import config from '../../../config';
import {
  baseAlphaNumericCompare,
  getRuDateString,
} from '../../../helpers/helpers';
import {
  GameProtocolDto,
  GameWithLogo,
  Highlight,
  HighlightType,
  PenaltyDto,
  PenaltyKind,
  SeasonsTeamCode,
} from '../../../types/Types';
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs';
import Spinner from '../../Spinner/Spinner';
import styles from './GameProtocolPage.module.scss';
import GoalStatsTable from './GameProtocolTables/GoalStatsTable/GoalStatsTable';
import PenaltyTable from './GameProtocolTables/PenaltyTable/PenaltyTable';
import ScoreTable from './GameProtocolTables/ScoreTable/ScoreTable';
import ShotsTable from './GameProtocolTables/ShotsTable/ShotsTable';
import GameProtocolTabs from './GameProtocolTabs/GameProtocolTabs';
import ScoreBanner from './GameProtocolTabs/ScoreBanner/ScoreBanner';
import { HighlightPenaltyPair, PenaltyData } from './GameProtocolPage.types';

const getPenaltyReason = (
  code: string,
  penaltyKinds?: PenaltyKind[]
): string => {
  if (!penaltyKinds) {
    return '';
  }

  const penaltyObject = penaltyKinds?.find(
    (p) => p?.attributes?.code?.toString() === code
  );

  return penaltyObject?.attributes?.ru || '';
};

const GameProtocolPage = observer((): ReactElement => {
  const [searchParams] = useSearchParams();
  const gameId = searchParams.get('gameId');
  const [selectedHighlight, setSelectedHighlight] = useState<Highlight>();
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [gameData, setGameData] = useState<GameWithLogo>();
  const [protocol, setGameProtocol] = useState<GameProtocolDto>();
  const [isLoading, setIsLoading] = useState(false);
  const [isGameError, setIsGameError] = useState(false);
  const [isProtocolError, setIsProtocolError] = useState(false);
  const { teamCode } = useParams();

  useEffect(() => {
    const getHighLights = async () => {
      if (gameData && gameData.id) {
        try {
          if (gameData.attributes?.highlights?.length) {
            setHighlights(gameData.attributes.highlights);
          } else {
            const highlightsData = await getGameHighlights(gameData.id);
            setHighlights(highlightsData);
          }
        } catch (error: any) {
          console.log(`Failed to load hihglights: ${error?.message}`);
        }
      }
    };

    getHighLights();
  }, [gameData]);

  const {
    articleHighlights,
    goalHighlights,
    penaltyHighlights,

    gameRecap,
    summaryHighlightsByPeriod,
  } = useMemo(() => {
    const summaryHighlights = highlights
      .filter((highlight) => highlight.type_id === HighlightType.Summary)
      .sort((a, b) => a.start_ts - b.start_ts);
    const gameRecap = summaryHighlights[summaryHighlights.length - 1];
    const summaryHighlightsByPeriod = summaryHighlights.slice(
      0,
      summaryHighlights.length - 1
    );

    return {
      articleHighlights: highlights
        .filter(
          (highlight) =>
            ![
              HighlightType.Penalty,
              HighlightType.Goal,
              HighlightType.Summary,
            ].includes(highlight.type_id)
        )
        .sort((a, b) => a.start_ts - b.start_ts),
      goalHighlights: highlights
        .filter((highlight) => highlight.type_id === HighlightType.Goal)
        .sort((a, b) => a.start_ts - b.start_ts),
      penaltyHighlights: highlights
        .filter((highlight) => highlight.type_id === HighlightType.Penalty)
        .sort((a, b) => a.start_ts - b.start_ts),
      gameRecap,
      summaryHighlightsByPeriod,
    };
  }, [highlights]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getGameData = async () => {
      try {
        if (gameId) {
          setIsLoading(true);
          const result = await getGameById(Number(gameId));
          setGameData(result?.data);
          setIsLoading(false);
        }
      } catch (e) {
        setIsGameError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getGameData();
  }, [gameId]);

  useEffect(() => {
    const getProtocolData = async () => {
      try {
        if (gameId) {
          const khlProtocolResult = await getGameProtocol(gameId);
          if (khlProtocolResult) {
            setGameProtocol({ GameSummary: khlProtocolResult.GameSummary });
          }
        }
      } catch (e: any) {
        console.error(
          'khlProtocolResult',
          e?.response?.data?.error || e?.message
        );
        const adminProtocolResult = await getGameProtocolAdmin(Number(gameId));
        if (adminProtocolResult?.data?.length) {
          setGameProtocol({
            GameSummary:
              adminProtocolResult?.data[0]?.attributes?.json?.GameSummary,
          });
        } else {
          setIsProtocolError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getProtocolData();

    let intervalId: NodeJS.Timer;

    if (gameData?.attributes.is_current) {
      intervalId = setInterval(getProtocolData, config.KhlProtocolUpdateTimer);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [gameData, gameId]);

  const getBreadcrumbsName = () => {
    if (!gameData) {
      return '';
    }
    const team1 = gameData?.attributes.team_1?.data.attributes.name;
    const team2 = gameData?.attributes.team_2?.data.attributes.name;
    const date = getRuDateString(new Date(gameData?.attributes.date));
    return team1 && team2 && date
      ? `${team1} vs ${team2} (${date.split(' ')[0].slice(0, -4)}${date
          .split(' ')[0]
          .slice(-2)}, ${date.split(' ')[1]})`
      : 'Не получены данные по команде и дате';
  };

  const [penaltyKinds, setPenaltyKinds] = useState<PenaltyKind[]>();
  const [penalties, setPenalties] = useState<PenaltyData[]>();
  const [totalOvertimes, setTotalOvertimes] = useState<number>(0);

  // Сопоставление видео и штрафов
  const pairData = useMemo(() => {
    // Группировка видео в группы с одинаковым временем
    const groupedHighlights: (Highlight & { highlightGroupId?: number })[] =
      JSON.parse(JSON.stringify(penaltyHighlights));
    const timeOfHighlight = (highlight: Highlight) => {
      return format(new Date(highlight.start_ts), 'HH:mm');
    };
    let groupIndex = 0;
    for (let index = 0; index < groupedHighlights.length; index++) {
      if (
        index !== 0 &&
        timeOfHighlight(groupedHighlights[index]) !==
          timeOfHighlight(groupedHighlights[index - 1])
      ) {
        groupIndex++;
      }
      groupedHighlights[index].highlightGroupId = groupIndex;
    }

    // Определение кол-ва групп
    let maxIndex = 0;
    for (let highlight of groupedHighlights) {
      if (highlight.highlightGroupId! > maxIndex) {
        maxIndex = highlight.highlightGroupId!;
      }
    }
    for (let p of penalties ?? []) {
      if (p.highlightGroupId > maxIndex) {
        maxIndex = p.highlightGroupId;
      }
    }

    // Сопоставление групп и поиск пар уже внутри групп по фамилиям.
    const pairs: HighlightPenaltyPair[] = [];

    for (let index = 0; index <= maxIndex; index++) {
      let highlightGroup = groupedHighlights.filter(
        (highlight) => highlight.highlightGroupId === index
      );
      let penaltyGroup =
        penalties?.filter((penalty) => penalty.highlightGroupId === index) ??
        [];

      while (highlightGroup.length > 0 && penaltyGroup.length > 0) {
        const firstPenaltyOfGroup = penaltyGroup[0];
        const foundHighlightOfGroup = highlightGroup.find((highlight) =>
          highlight.name.includes(firstPenaltyOfGroup.playerName)
        );
        if (foundHighlightOfGroup) {
          pairs.push({
            highlight: foundHighlightOfGroup as Highlight,
            penalty: firstPenaltyOfGroup,
          });
          highlightGroup = highlightGroup.filter(
            (highlight) => highlight.id !== foundHighlightOfGroup.id
          );
        }
        penaltyGroup = penaltyGroup.slice(1);
      }
    }

    return pairs;
  }, [penaltyHighlights, penalties]);

  // Объединение видео без категории и видео штрафов, которые не были сопоставлены
  const allArticleHighlights = useMemo(() => {
    return [
      ...articleHighlights,
      ...penaltyHighlights.filter(
        (highlight: Highlight) =>
          !pairData.find((pair) => pair.highlight.id === highlight.id)
      ),
    ];
  }, [articleHighlights, pairData, penaltyHighlights]);

  useEffect(() => {
    if (protocol) {
      const isShootouts = !!protocol?.GameSummary?.Shootout;

      if (isShootouts) {
        setTotalOvertimes(1);
      } else {
        const penaltiesPeriods = penalties
          ? Array.from(
              new Set(penalties.map((penalty) => parseInt(penalty.period)))
            )
          : [];

        const khlGoals = Array.isArray(protocol.GameSummary.Goals.Goal)
          ? protocol.GameSummary.Goals.Goal
          : [protocol.GameSummary.Goals.Goal];
        const goalsPeriods = Array.from(
          new Set(khlGoals.map((goal) => parseInt(goal['@_per'])))
        );

        const totalPeriods = Array.from(
          new Set([...penaltiesPeriods, ...goalsPeriods])
        ).sort((a, b) => a - b);

        setTotalOvertimes(totalPeriods[totalPeriods.length - 1] - 3);
      }
    }
  }, [protocol, penalties]);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await getFoulsList();
        setPenaltyKinds(resp?.data);
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (protocol && gameData) {
      const teams = {
        [protocol.GameSummary['@_homeId']]: {
          khlId: protocol.GameSummary['@_teama'],
          name: gameData.attributes.team_1.data.attributes.name,
          logo: gameData.attributes.team_1.data.attributes.logo.data.attributes
            .url,
        },
        [protocol.GameSummary['@_visitorId']]: {
          khlId: protocol.GameSummary['@_teamb'],
          name: gameData.attributes.team_2.data.attributes.name,
          logo: gameData.attributes.team_2.data.attributes.logo.data.attributes
            .url,
        },
      };

      const penaltiesData = protocol.GameSummary.Penalties.length
        ? protocol.GameSummary.Penalties.reduce((result, item, index) => {
            if (!item.Penalty) {
              return result;
            }

            if (Array.isArray(item.Penalty)) {
              const penalties = item.Penalty.reduce((res, obj) => {
                if (obj['@_pnum'] === '0') {
                  res.push({
                    period: obj['@_per'],
                    time: obj['@_tbeg'],
                    duration: obj['@_time'],
                    code: obj['@_code'],
                    reason: getPenaltyReason(obj['@_code'], penaltyKinds),
                    playerNumber: obj['@_pnum'],
                    playerName: 'Командный штраф',
                    team: Object.values(teams).find(
                      (t) => t.khlId === item['@_teamId']?.toString()
                    ),
                  } as PenaltyData);
                } else {
                  const player = protocol.GameSummary?.PlayerStatsList[
                    index
                  ].PlayerStats.find(
                    (p) => p['@_jn']?.toString() === obj['@_pnum']
                  );

                  if (player && player['@_clubidt']) {
                    res.push({
                      period: obj['@_per'],
                      time: obj['@_tbeg'],
                      duration: obj['@_time'],
                      code: obj['@_code'],
                      reason: getPenaltyReason(obj['@_code'], penaltyKinds),
                      playerNumber: obj['@_pnum'],
                      playerName: player['@_lastname'],
                      team: teams[player['@_clubidt']?.toString()],
                    } as PenaltyData);
                  }
                }

                return res;
              }, [] as PenaltyData[]);

              result.push(...penalties);
            } else {
              const obj = item.Penalty as PenaltyDto;

              if (obj['@_pnum'] === '0') {
                result.push({
                  period: obj['@_per'],
                  time: obj['@_tbeg'],
                  duration: obj['@_time'],
                  code: obj['@_code'],
                  reason: getPenaltyReason(obj['@_code'], penaltyKinds),
                  playerNumber: obj['@_pnum'],
                  playerName: 'Командный штраф',
                  team: Object.values(teams).find(
                    (t) => t.khlId === item['@_teamId']?.toString()
                  ),
                } as PenaltyData);
              } else {
                const player = protocol.GameSummary?.PlayerStatsList[
                  index
                ].PlayerStats.find(
                  (p) => p['@_jn']?.toString() === obj['@_pnum']
                );

                if (player && player['@_clubidt']) {
                  result.push({
                    period: obj['@_per'],
                    time: obj['@_tbeg'],
                    duration: obj['@_time'],
                    code: obj['@_code'],
                    reason: getPenaltyReason(obj['@_code'], penaltyKinds),
                    playerNumber: obj['@_pnum'],
                    playerName: player['@_lastname'],
                    team: teams[player['@_clubidt']?.toString()],
                  } as PenaltyData);
                }
              }
            }

            return result;
          }, [] as PenaltyData[])
        : [];

      const sortedData = penaltiesData?.sort((a, b) =>
        baseAlphaNumericCompare(a.time, b.time)
      );

      // Группировка штрафов в группы с одинаковым временем
      let highlightIndex = 0;
      for (
        let penaltyIndex = 0;
        penaltyIndex < sortedData.length;
        penaltyIndex++
      ) {
        if (
          penaltyIndex !== 0 &&
          sortedData[penaltyIndex].time !== sortedData[penaltyIndex - 1].time
        ) {
          highlightIndex++;
        }
        sortedData[penaltyIndex].highlightGroupId = highlightIndex;
      }

      setPenalties(sortedData);
    }
  }, [gameData, protocol, penaltyKinds]);

  return (
    <ImageBgPageWrapper>
      <InfoWrapper
        borderRadius='0px'
        innerPaddingTop='20px'
        isWhiteBackground={false}
      >
        <div className={styles.headerWrapper}>
          <div className={styles.breadcrumbsWrapper}>
            <Breadcrumbs
              isWhiteColor={false}
              breadcrumbs={
                gameData
                  ? [
                      {
                        name: 'Календарь матчей',
                        url: `/schedule/${
                          teamCode ? teamCode : SeasonsTeamCode.men
                        }`,
                      },
                      {
                        name: getBreadcrumbsName(),
                        url: `/protocol?gameId=${gameData?.id}`,
                      },
                    ]
                  : [
                      {
                        name: 'Календарь матчей',
                        url: `/schedule/${
                          teamCode ? teamCode : SeasonsTeamCode.men
                        }`,
                      },
                    ]
              }
            />
          </div>
          <h1 className={styles.title}>Статистика матча</h1>
        </div>
        {gameData && protocol && !isGameError && !isProtocolError ? (
          <ScoreBanner game={gameData} protocol={protocol} />
        ) : (
          <></>
        )}
        <div className={styles.wrapper}>
          {isLoading ? (
            <Spinner className={styles.loader} />
          ) : gameData || protocol ? (
            <>
              <div className={styles.content}>
                <GameProtocolTabs
                  game={gameData}
                  protocol={protocol}
                  article={gameData?.attributes?.article?.data}
                  highlights={allArticleHighlights}
                  recap={gameRecap}
                  setSelectedHighlight={setSelectedHighlight}
                />
              </div>
              <div className={styles.sideMenu}>
                {gameData && protocol && protocol.GameSummary && (
                  <div className={styles.sideTables}>
                    <ScoreTable game={gameData} protocol={protocol} />
                    <GoalStatsTable
                      game={gameData}
                      protocol={protocol}
                      goalHighlights={goalHighlights}
                      summaryHighlights={summaryHighlightsByPeriod}
                      setSelectedHighlight={setSelectedHighlight}
                    />
                    <PenaltyTable
                      game={gameData}
                      protocol={protocol}
                      penaltyHighlights={penaltyHighlights}
                      setSelectedHighlight={setSelectedHighlight}
                      penalties={penalties ?? []}
                      totalOvertimes={totalOvertimes}
                      pairData={pairData}
                    />
                    <ShotsTable game={gameData} protocol={protocol} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <ErrorWidget.Error />
          )}
        </div>
      </InfoWrapper>
      <VideoModal
        isVisible={!!selectedHighlight}
        videoSource={selectedHighlight?.url || ''}
        close={() => setSelectedHighlight(undefined)}
        isIFrame
      />
    </ImageBgPageWrapper>
  );
});

export default GameProtocolPage;
