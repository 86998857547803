import styles from './VideoModal.module.scss';
import { Modal } from 'antd';
import YouTube from 'react-youtube';

interface VideoModalProps {
  isVisible: boolean;
  videoSource: string;
  isYoutube?: boolean;
  isIFrame?: boolean;
  close: () => void;
}

const VideoModal = ({
  isVisible,
  videoSource,
  isYoutube,
  isIFrame,
  close,
}: VideoModalProps) => {
  const renderVideo = (videoSource: string) => {
    return (
      <video className={styles.video} controls controlsList='nodownload'>
        <source src={videoSource} />
      </video>
    );
  };

  const renderYoutubeVideo = (videoSource: string) => {
    return (
      <YouTube
        key={videoSource}
        className={styles.player}
        videoId={videoSource}
        opts={{ height: '100%', width: '100%' }}
      />
    );
  };

  const renderIframe = (videoSource: string) => {
    return (
      <iframe
        title='video player'
        src={videoSource}
        allow='encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
        allowFullScreen
        className={styles.video}
      ></iframe>
    );
  };

  return (
    <Modal
      open={isVisible}
      onCancel={close}
      closable={false}
      className={styles.modal}
      footer={null}
      centered
      destroyOnClose={true}
    >
      <div className={styles.modalContent}>
        {isIFrame
          ? renderIframe(videoSource)
          : isYoutube
          ? renderYoutubeVideo(videoSource)
          : renderVideo(videoSource)}
      </div>
    </Modal>
  );
};

export default VideoModal;
