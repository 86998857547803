import { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { PlayerWithRelations } from '../../../types/Types';
import styles from './PlayerTabsWithInfo.module.scss';
import PlayerStatistic from '../PlayerStatistic/PlayerStatistic';
import TopStory from '../../TopStory/TopStory';
import ViewMoreButton from '../../ViewMoreButton/ViewMoreButton';
import { getPlayerArticles } from '../../../api/api';
import Biography from './Biography/Biography';
import ShowOn from 'components/core/adaptivity/ShowOn';
import PlayerCarrierStatistic from '../PlayerCarrierStatistic/PlayerCarrierStatistic';
import { useTranslation } from 'react-i18next';
import MediaTab from '../../personPagesCommons/MediaTab/MediaTab';

const NEWS_PAGE_AMOUNT = 9;

type PlayerTabsWithInfoProps = {
  player: PlayerWithRelations;
};

const PlayerTabsWithInfo = ({
  player,
}: PlayerTabsWithInfoProps): ReactElement => {
  const { t } = useTranslation();

  const lokoStatsRef = useRef<HTMLDivElement>(null);
  const carrierStatsRef = useRef<HTMLDivElement>(null);
  const articlesRef = useRef<HTMLDivElement>(null);
  const photoRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] =
    useState<RefObject<HTMLDivElement>>(aboutRef);

  const [newsCount, setNewsCount] = useState<number>(NEWS_PAGE_AMOUNT);
  const [topArticles, setTopArticles] = useState([]);
  const [totalArticlesCount, setTotalArticlesCount] = useState(0);

  useEffect(() => {
    const getArticles = async () => {
      try {
        if (player) {
          const articles = await getPlayerArticles(
            `${player?.attributes?.name} ${player?.attributes?.surname}`,
            newsCount,
            0
          );
          setTopArticles(articles.data);
          setTotalArticlesCount(articles.meta.pagination.total);
        }
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };
    getArticles();
  }, [player, newsCount]);

  function changeActiveTab(ref: RefObject<HTMLDivElement>) {
    const currentElement = activeTab.current;
    if (currentElement?.className.includes(styles.textShow)) {
      currentElement?.classList.remove(styles.textShow);
    }
    const element = ref.current;
    if (!element?.className.includes(styles.textShow)) {
      element?.classList.add(styles.textShow);
    }
    setActiveTab(ref);
  }

  const handleViewMoreButtonClick = () => {
    setNewsCount(newsCount + NEWS_PAGE_AMOUNT);
  };

  return (
    <>
      <div className={styles.tabBar} id='tab-bar'>
        <a
          onClick={() => changeActiveTab(aboutRef)}
          className={activeTab === aboutRef ? styles.active : ''}
          data-tab='about-player'
        >
          <span
            className={activeTab === aboutRef ? styles.active : styles.span}
          >
            {t('playerPage.tabs.biography')}
          </span>
        </a>
        <a
          onClick={() => changeActiveTab(lokoStatsRef)}
          className={activeTab === lokoStatsRef ? styles.active : ''}
          data-tab='carrier-stats'
        >
          <span
            className={activeTab === lokoStatsRef ? styles.active : styles.span}
          >
            {t('playerPage.tabs.stats')}
          </span>
        </a>
        {player.attributes.statistic &&
          player.attributes.statistic.length > 0 && (
            <a
              onClick={() => changeActiveTab(carrierStatsRef)}
              className={activeTab === carrierStatsRef ? styles.active : ''}
              data-tab='carrier-stats'
            >
              <span
                className={
                  activeTab === carrierStatsRef ? styles.active : styles.span
                }
              >
                {t('playerPage.tabs.carrierStats')}
              </span>
            </a>
          )}
        <a
          onClick={() => changeActiveTab(photoRef)}
          className={activeTab === photoRef ? styles.active : ''}
          data-tab='photo-gallery'
        >
          <span
            className={activeTab === photoRef ? styles.active : styles.span}
          >
            {t('playerPage.tabs.photoAndVideo')}
          </span>
        </a>
        <a
          onClick={() => changeActiveTab(articlesRef)}
          className={activeTab === articlesRef ? styles.active : ''}
          data-tab='articles'
        >
          <span
            className={activeTab === articlesRef ? styles.active : styles.span}
          >
            {t('playerPage.tabs.news')}
          </span>
        </a>
      </div>
      <div className={styles.articleText}>
        <div
          ref={aboutRef}
          className={classNames(styles.text, styles.textShow)}
          id='about-player'
        >
          <Biography player={player} />
        </div>

        <div ref={lokoStatsRef} className={styles.text} id='carrier-stats'>
          <PlayerStatistic player={player} />
        </div>

        <div ref={carrierStatsRef} className={styles.text} id='carrier-stats'>
          <PlayerCarrierStatistic player={player} />
        </div>

        <div ref={articlesRef} className={styles.text} id='articles'>
          {topArticles.length !== 0 ? (
            <>
              <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
                <TopStory
                  articles={topArticles}
                  startIndex={0}
                  count={newsCount}
                  isGridStyle
                  useImage
                />
              </ShowOn>
              <ShowOn largeMobile smallMobile>
                <TopStory
                  articles={topArticles}
                  startIndex={0}
                  count={newsCount}
                  isColumnStyle
                  useImage
                />
              </ShowOn>
              {totalArticlesCount < newsCount ? (
                <></>
              ) : (
                <ViewMoreButton
                  onClick={() => {
                    handleViewMoreButtonClick();
                  }}
                />
              )}
            </>
          ) : (
            <p>{t('playerPage.tabs.nothingHereYet')}</p>
          )}
        </div>
        <div ref={photoRef} className={styles.text} id='photo-gallery'>
          <MediaTab
            name={player?.attributes?.name}
            surname={player?.attributes?.surname}
          />
        </div>
      </div>
    </>
  );
};

export default PlayerTabsWithInfo;
