import { ReactElement } from 'react';
import { CommonVideo, DefaultImageObject } from '../../../types/Types';
import styles from './PlayerVideo.module.scss';
import { getRuDateString } from 'helpers/helpers';

type PlayerVideoProps = {
  video: CommonVideo;
  isVkVideo?: boolean;
  openVideo: (source: string, isVkVideo: boolean) => void;
  index: number;
  defaultImage?: DefaultImageObject;
};

function PlayerVideo({
  video,
  isVkVideo,
  openVideo,
  index,
  defaultImage,
}: PlayerVideoProps): ReactElement {
  const onVideoClick = () => {
    openVideo(video.url, !!isVkVideo);
  };

  return (
    <div
      key={`${video.id}_${index}`}
      className={styles.video}
      onClick={onVideoClick}
    >
      <img
        className={styles.previewImage}
        src={
          isVkVideo
            ? video.previewUrl
            : video.previewUrl
            ? video.previewUrl
            : defaultImage
            ? defaultImage.attributes.image.data.attributes.url
            : ''
        }
        alt={video.title}
      />
      <div className={styles.description}>
        <div className={styles.title}>
          {video.title.replace(/&quot;|&#039;/g, '"')}
        </div>
        <div className={styles.date}>
          {getRuDateString(new Date(video.date))}
        </div>
      </div>
    </div>
  );
}

export default PlayerVideo;
