import { makeAutoObservable } from 'mobx';
import { getAdditionalProducts, getProducts } from '../../api/api';
import { getNRandomInts } from '../../helpers/helpers';
import { Product } from '../../types/Types';

class ProductsStore {
  constructor() {
    makeAutoObservable(this);
  }

  isLoading: boolean = true;
  isError: boolean = false;
  products: Array<Product> = [];
  static readonly widgetSize = 4;

  async loadProducts() {
    try {
      const resp = await getProducts();
      if (resp.length < ProductsStore.widgetSize) {
        await this.addProducts(ProductsStore.widgetSize, resp);
      } else {
        this.products = resp;
      }
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  async addProducts(widgetSize: number, resp: Array<Product>) {
    try {
      const fillerProducts = await getAdditionalProducts(
        widgetSize - resp.length
      );

      this.products = resp.concat(fillerProducts);
    } catch {
      this.isError = true;
    }
  }

  getRandomProducts(): Array<Product> {
    const indexes = getNRandomInts(
      ProductsStore.widgetSize,
      this.products.length
    );

    return indexes.map((index) => this.products[index]);
  }
}

export default ProductsStore;
