import { FC } from 'react';
import styles from './VideoItem.module.scss';

type VideoItemProps = {
  isMain?: boolean;
  title: string;
  description: string;
  imageSrc: string;
  countersText: string;
  onClick: () => void;
};

const VideoItem: FC<VideoItemProps> = ({
  isMain,
  title,
  description,
  imageSrc,
  countersText,
  onClick,
}) => {
  return (
    <div className={isMain ? styles.mainVideo : styles.video} onClick={onClick}>
      <img
        className={isMain ? styles.previewImageMainVideo : styles.previewImage}
        src={imageSrc}
        alt={title}
      />
      <div
        className={isMain ? styles.descriptionMainVideo : styles.description}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.date}>{description}</div>
        <div className={styles.date}>{countersText}</div>
      </div>
    </div>
  );
};

export default VideoItem;
