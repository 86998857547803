const Url = {
  socials: {
    VK: 'https://vk.com/hclokomotiv_official',
    TELEGRAM: 'https://t.me/hclokomotiv_official',
    YOUTUBE: 'https://www.youtube.com/@hclokomotivyaroslavl/streams',
    VK_VIDEO: 'https://vkvideo.ru/@hclokomotiv_official',
  },
  loko: {
    TICKETS: 'https://tickets.hclokomotiv.ru/',
    LOKOMANIA: 'https://bonus.hclokomotiv.ru/',
    SHOP: 'https://shop.hclokomotiv.ru/',
    LOKO: 'https://mhl.hclokomotiv.ru/?teamCode=lko',
    LOKO76: 'https://mhl.hclokomotiv.ru/?teamCode=l76',
    ACADEMY: 'https://academy.hclokomotiv.ru/',
  },
};

export default Url;
