import { ReactElement, useRef, useState } from 'react';
import { ReactComponent as Logo } from '../../../assets/new_logo.svg';
import { ReactComponent as LogoText } from '../../../assets/logo_text.svg';
import { ReactComponent as Ticket } from '../../../assets/menu_icons/ticket.svg';
import { ReactComponent as Tshirt } from '../../../assets/menu_icons/tshirt.svg';
import styles from './LogoNavigation.module.scss';
import { MenuElementDto } from '../../../types/Types';
import { ReactComponent as VK } from '../../../assets/menu_icons/vk.svg';
import { ReactComponent as Telegram } from '../../../assets/menu_icons/tg.svg';
import { ReactComponent as YouTube } from '../../../assets/menu_icons/youtube.svg';
import { compareOrder } from '../../../helpers/helpers';
import Container from '../../common/Container/Container';
import Url from 'constants/urls';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HeaderLinks from '../MobileHeaderDrawerLinks/HeaderLinks';
import VKVideoIcon from 'assets/icons/VKVideoIcon';

type LogoNavigationProps = {
  menuElement?: MenuElementDto;
  closeMenu: Function;
  openMenuMouseEnter: Function;
  isScroll: boolean;
};

function LogoNavigation({
  menuElement,
  closeMenu,
  openMenuMouseEnter,
  isScroll,
}: LogoNavigationProps): ReactElement {
  const { t } = useTranslation();
  const liRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState(false);
  const [activeTabTickets, setActiveTabTickets] = useState(false);
  return (
    <div className={styles.logoNavigation}>
      <Container paddingHorizontal='15px'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <a href='/'>
            <div className={styles.logoWrapper}>
              <div className={styles.logo} id={'logo-wrapper'}>
                <div className='logo'>
                  <Logo />
                </div>
              </div>
              <div className={styles.logoText}>
                <LogoText />
              </div>
            </div>
          </a>
          <HeaderLinks isDesktop />
          <div className={styles.navWrapper}>
            <nav className={styles.nav}>
              <div
                className={styles.navInnerWrapper}
                onMouseEnter={() => setActiveTabTickets(true)}
                onMouseLeave={() => setActiveTabTickets(false)}
              >
                <div className={styles.ticketsWrapper} id={'tickets-button'}>
                  <a
                    href={Url.loko.TICKETS}
                    className={styles.navItem}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Ticket />
                    <span className={styles.navItemText}>
                      {t('header.tickets')}
                    </span>
                  </a>
                </div>
                <div className={styles.line}></div>
                {activeTabTickets && (
                  <div className={styles.ticketsDropdown}>
                    {menuElement &&
                      menuElement.attributes.main_menu_item.data
                        .sort(compareOrder)
                        .map((submenu) =>
                          submenu.attributes.is_external ? (
                            <a
                              className={styles.dropdownItem}
                              key={submenu.id}
                              href={submenu.attributes.url}
                              target={'_blank'}
                              rel='noreferrer'
                            >
                              <span className={styles.dropdownItemTicketText}>
                                {submenu.attributes.display_name}
                              </span>
                            </a>
                          ) : (
                            <Link
                              className={styles.dropdownItem}
                              key={submenu.id}
                              to={submenu.attributes.url}
                              target={''}
                              rel='noreferrer'
                            >
                              <span className={styles.dropdownItemTicketText}>
                                {submenu.attributes.display_name}
                              </span>
                            </Link>
                          )
                        )}
                  </div>
                )}
              </div>
              <a
                href={Url.loko.SHOP}
                className={styles.navItem}
                id={'shop-button'}
                target='_blank'
                rel='noreferrer'
              >
                <Tshirt />
                <span className={styles.navItemText}>{t('header.shop')}</span>
              </a>
            </nav>

            <div
              className={styles.socials}
              ref={liRef}
              onMouseEnter={() => {
                openMenuMouseEnter(liRef);
                setActiveTab(true);
              }}
              onMouseLeave={() => {
                closeMenu(liRef);
                setActiveTab(false);
              }}
            >
              <span className={styles.socialsText}>{t('header.socials')}</span>

              {activeTab && (
                <div className={styles.socialsDropdown}>
                  <div className={styles.dropdownMenuContainer}>
                    <a
                      href={Url.socials.VK}
                      className={styles.dropdownItemContainer}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <VK />
                      <span className={styles.dropdownItem}>
                        {t('header.vk')}
                      </span>
                    </a>
                    <a
                      href={Url.socials.TELEGRAM}
                      className={styles.dropdownItemContainer}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Telegram />
                      <span className={styles.dropdownItem}>
                        {t('header.tg')}
                      </span>
                    </a>
                    <a
                      href={Url.socials.VK_VIDEO}
                      className={styles.dropdownItemContainer}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <VKVideoIcon height='12' width='12'/>
                      <span className={styles.dropdownItem}>
                        {t('header.vkVideo')}
                      </span>
                    </a>
                    <a
                      href={Url.socials.YOUTUBE}
                      className={styles.dropdownItemContainer}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <YouTube />
                      <span className={styles.dropdownItem}>
                        {t('header.yt')}
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default LogoNavigation;
