import classNames from 'classnames';
import { ReactElement, useState } from 'react';
import { ExecutiveDto } from '../../types/Types';
import ExecutiveStatisticTable from '../ExecutiveStatisticTable/ExecutiveStatisticTable';
import styles from './CoachTabsWithInfo.module.scss';
import ShowMoreText from '../ShowMoreText/ShowMoreText';
import { useTranslation } from 'react-i18next';
import MediaTab from '../personPagesCommons/MediaTab/MediaTab';

type CoachTabsWithInfoProps = {
  coach: ExecutiveDto;
};

function CoachTabsWithInfo({ coach }: CoachTabsWithInfoProps): ReactElement {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <>
      <div className={styles.tabBar}>
        <p
          className={classNames(styles.tab, {
            [styles.active]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          {t('coachPage.tabs.biography')}
        </p>
        {coach.attributes?.player_career?.length ||
        coach.attributes?.executive_career?.length ? (
          <p
            className={classNames(styles.tab, {
              [styles.active]: activeTab === 1,
            })}
            onClick={() => setActiveTab(1)}
          >
            {t('coachPage.tabs.career')}
          </p>
        ) : null}
        {coach.attributes?.statistics?.length ? (
          <p
            className={classNames(styles.tab, {
              [styles.active]: activeTab === 2,
            })}
            onClick={() => setActiveTab(2)}
          >
            {t('coachPage.tabs.stats')}
          </p>
        ) : null}
        <p
          className={classNames(styles.tab, {
            [styles.active]: activeTab === 3,
          })}
          onClick={() => setActiveTab(3)}
        >
          {t('coachPage.tabs.photoAndVideo')}
        </p>
      </div>
      <div className={styles.article}>
        {activeTab === 0 && (
          <>
            {coach.attributes?.biography?.length ? (
              <>
                <ShowMoreText
                  content={coach.attributes.biography}
                  marginTop={30}
                  marginBottom={30}
                />
              </>
            ) : (
              <p>{t('coachPage.tabs.nothingHereYet')}</p>
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            {coach.attributes?.player_career?.length ? (
              <>
                <p className={styles.title}>
                  {t('coachPage.tabs.playerCareer')}
                </p>
                <ShowMoreText
                  content={coach.attributes.player_career}
                  marginTop={30}
                  marginBottom={30}
                />
              </>
            ) : null}
            {coach.attributes?.executive_career?.length ? (
              <>
                <p className={styles.title}>
                  {t('coachPage.tabs.executiveCareer')}
                </p>
                <ShowMoreText
                  content={coach.attributes.executive_career}
                  marginTop={30}
                  marginBottom={30}
                />
              </>
            ) : null}
          </>
        )}
        {activeTab === 2 && <ExecutiveStatisticTable executive={coach} />}
        {activeTab === 3 && (
          <MediaTab
            name={coach?.attributes?.name}
            surname={coach?.attributes?.surname}
          />
        )}
      </div>
    </>
  );
}

export default CoachTabsWithInfo;
