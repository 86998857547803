import Article from 'model/entity/loko/type/Article';
import { getPlayerByIdLeague } from '../api/api';
import config from '../config';
import {
  DAYS_OF_WEEK,
  DAYS_SHORT_OF_WEEK,
  FACEBOOK_PLUGIN,
  MONTH,
  MONTHS,
  starts_hcl_year,
  starts_mhl_year,
} from '../constants/constants';
import {
  ArenaAboutObject,
  ArenaPointsType,
  ExecutiveDto,
  ExecutiveRosterDto,
  GameWithLogo,
  PartnerDto,
  PlayerSeasonStatsDto,
  PlayerSeasonStatsDtoReadable,
  PlayerWithRelations,
  PlayoffStandingsDto,
  PlayoffStandingsReadableDto,
  Roster,
  RosterDto,
  ScheduleGame,
  SortElement,
  StandingsDto,
  StandingsReadableDto,
  StandingsTypes,
  SubMenuElementDto,
  TopPartnersDto,
  TopPlayerCardType,
  VKVideosVideo,
  VKVideosVideoImage,
} from '../types/Types';
import { default as ImageDto } from 'model/entity/loko/type/Image';
import Regexp from 'constants/regexp';
import { t } from 'i18next';
import { differenceInYears } from 'date-fns';

export function getDateInfo(article: Article) {
  const today = new Date();
  const date = new Date(article?.attributes?.date);

  if (
    today.getDate() === date.getDate() &&
    today.getMonth() === date.getMonth() &&
    today.getFullYear() === date.getFullYear()
  ) {
    const dateInfo = date.toLocaleTimeString('ru-RU', {
      hour: 'numeric',
      minute: 'numeric',
    });
    return `сегодня ${dateInfo}`;
  } else {
    const dateInfo = date.toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
    if (dateInfo !== 'Invalid Date') return dateInfo;
  }
}

export function getFacebookLink(article: Article, isBoxCount: boolean = false) {
  const local = 'locale=en_US';
  const after = `width&layout=${
    isBoxCount ? 'box_count' : 'button_count'
  }&action=like&show_faces=false&share=false`;
  const linkToThePost = `${config.HCLFrontend.baseUrl}/acticle/${article.id}`;
  return `${FACEBOOK_PLUGIN}?${local}&href=${linkToThePost}amp;${after}`;
}

export function getCountDaysInMonth(
  month: number = 0,
  year: number = 2023
): number {
  const currentDate = month === 12 ? new Date() : new Date(year, month, 1);
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();
}

export function getMatchDateInfo(
  gameDate: Date,
  isTimeNeeded: boolean = false
) {
  if (gameDate) {
    const date = new Date(gameDate);
    const dateString = date.toLocaleDateString('ru-RU', {
      month: 'long',
      day: 'numeric',
    });
    const timeString = date.toLocaleTimeString('ru-RU', {
      hour: isTimeNeeded ? '2-digit' : undefined,
      minute: isTimeNeeded ? '2-digit' : undefined,
    });
    const result = `${DAYS_SHORT_OF_WEEK.get(
      date.getDay()
    )} ${dateString} ${date.getFullYear()}, ${timeString}`;
    return result;
  }
  return '';
}

export function isContainsHTMLTags(text: string | undefined) {
  const pattern = /<.*?>/g;
  const matches = text && text.match(pattern);

  return matches && matches.length > 0;
}

export function getDateOrTimeForAdvertisingWidget(
  isNeedDate: boolean,
  gameDate: Date | undefined
) {
  if (!gameDate) {
    return '';
  }
  const date = new Date(gameDate);

  if (isNeedDate) {
    return date.toLocaleDateString('ru-RU', {
      month: 'long',
      day: 'numeric',
    });
  } else {
    return date.toLocaleTimeString('ru-RU', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }
}

export function getLocaleDateStringWithTime(
  date: Date,
  needTimezone?: boolean
) {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  if (needTimezone) {
    options.timeZone = 'Europe/Moscow';
  }

  return date.toLocaleDateString('ru-RU', options);
}

export function getTimeOfGame(game: GameWithLogo | ScheduleGame) {
  if (!(game && game.attributes && game.attributes.date)) {
    return '';
  }
  const date = new Date(game.attributes.date);
  if (date.getUTCHours() === 21) {
    return '';
  }
  return date.toLocaleTimeString('default', {
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function getMonthLocalized(inMonth: number) {
  const date = new Date();
  date.setMonth(inMonth);
  return date.toLocaleString('default', { month: 'long' });
}
export function getMonthName(month: number) {
  return `${MONTH.get(month)}`;
}

export function getAllLocalizedMonthes() {
  return Array.from(Array(12), (_, i) => {
    return {
      key: i + 1,
      value: getMonthLocalized(i),
    };
  });
}

export function getAllYears() {
  const currentYear = new Date().getFullYear();
  const startYear = 2015;
  const result = [];

  for (let i = startYear; i <= currentYear; i++) {
    result.unshift(i);
  }

  return result;
}

export function doFilterRosterByPosition(
  rosters: Array<RosterDto>,
  positionId: number
) {
  return rosters.filter((player: RosterDto) => {
    if (
      player.attributes?.player?.data?.attributes?.position?.data?.id ===
      positionId
    ) {
      return true;
    }
    return false;
  });
}

export function doSortByDisplayOrder<T extends RosterDto | ExecutiveDto>(
  rosters: Array<T>
) {
  return rosters.sort((a: T, b: T) => {
    if (!a.attributes.display_order) {
      return 1;
    }
    if (!b.attributes.display_order) {
      return -1;
    }
    return a.attributes.display_order - b.attributes.display_order;
  });
}

export function getRuDateString(date: Date) {
  return (
    `${getTwoDigitNumber(date.getDate().toString())}.${getTwoDigitNumber(
      (date.getMonth() + 1).toString()
    )}.${date.getFullYear()} ` +
    `${getTwoDigitNumber(date.getHours().toString())}:${getTwoDigitNumber(
      date.getMinutes().toString()
    )}`
  );
}

export function getSQLDateTimeString(date: Date) {
  return (
    `${date.getFullYear()}-${getTwoDigitNumber(
      (date.getMonth() + 1).toString()
    )}-${getTwoDigitNumber(date.getDate().toString())} ` +
    `${getTwoDigitNumber(date.getHours().toString())}:${getTwoDigitNumber(
      date.getMinutes().toString()
    )}`
  );
}
export function getNameSurname(playerStats: PlayerSeasonStatsDtoReadable) {
  let name = playerStats.namelocal;
  let surname = playerStats.lastname;
  if (!name) {
    name = playerStats.firstname;
  }

  if (!name || !surname) {
    const splitName = playerStats.fullname?.split(' ');
    if (splitName?.length === 2) {
      // usually KHL stores names as 'surname name' and we need reverse
      return `${splitName[1]} ${splitName[0]}`;
    }
    return playerStats.fullname || '-';
  }

  return `${name} ${surname}`;
}

export function getNameSurnameForProtocol(
  fullName: string | PlayerSeasonStatsDtoReadable
) {
  const name =
    typeof fullName === 'string' ? fullName : getNameSurname(fullName);
  const fullNameArray = name?.split(' ');
  return (
    fullNameArray[0]?.slice(0, 1) +
    '. ' +
    (fullNameArray[1] ? fullNameArray[1] : '') +
    (fullNameArray[2] ? fullNameArray[2] : '')
  );
}

export function mapPlayersToReadable(
  players: Array<PlayerSeasonStatsDto>
): Array<PlayerSeasonStatsDtoReadable> {
  return players.map(
    (x: PlayerSeasonStatsDto): PlayerSeasonStatsDtoReadable => {
      return {
        id: x['@_id'] ? Number(x['@_id']) : undefined,
        position: x['@_pos'],
        fullname: x['@_name'],
        lastname: x['@_lastname'],
        firstname: x['@_firstname'],
        games_played: x['@_gp'],
        number: x['@_jn'],
        goals: x['@_g'],
        assists: x['@_a'],
        points: x['@_pts'],
        plus_minus: x['@_pm'],
        penalty_in_minutes: x['@_pim'],
        game_winning_goals: x['@_gwg'],
        faceoffs: x['@_fo'],
        faceoffs_wons: x['@_fow'],
        faceoffs_wons_percent: x['@_fo_pct'],
        goals_against: x['@_ga'],
        goals_against_average: Number(x['@_gaa'])
          ? Number(x['@_gaa'])
          : undefined,
        time_on_ice: x['@_toi'],
        savings: x['@_sv'],
        savings_percent: x['@_sv_pct'],
        shootouts: x['@_so'],
        tka: x['@_tka'],
        plus: x['@_plus'],
        minus: x['@_minus'],
        eqgoals: x['@_eqg'],
        power_play_goals: x['@_ppg'],
        shorthanded_goals: x['@_shg'],
        overtime_goals: x['@_otg'],
        shootouts_deciding_shots: x['@_sds'],
        shots_on_goal: x['@_sog'],
        shots_on_goal_percent: x['@_sog_pct'],
        shots_on_goal_average: x['@_sog_avg'],
        time_on_ice_average: x['@_toi_avg'],
        shifts_on_game_average: x['@_sft_avg'],
        time_in_equals_average: x['@_tie_avg'],
        shootouts_in_equals_average: x['@_sfte_avg'],
        time_in_more_average: x['@_tipp_avg'],
        shots_in_more_average: x['@_sftpp_avg'],
        time_in_less_average: x['@_tish_avg'],
        shots_in_less_average: x['@_sftsh_avg'],
        hits: x['@_hits'],
        blocks: x['@_bls'],
        foals_against: x['@_foa'],
        wins: x['@_w'],
        losses: x['@_l'],
        game_with_b_series: x['@_sop'],
      };
    }
  );
}

export function mapStandingsToReadable(
  standings: Array<StandingsDto>
): Array<StandingsReadableDto> {
  return standings.map((x: StandingsDto): StandingsReadableDto => {
    return {
      club_id: x['@_clubid'],
      conference:
        x['@_conference'] ?? (Number(x['@_division']) < 3 ? '1' : '2'),
      conference_name:
        x['@_conference_name'] ??
        (Number(x['@_division']) < 3 ? 'Запад' : 'Восток'),
      conference_rank: x['@_conference_rank'],
      division: x['@_division'],
      division_name: x['@_division_name'],
      division_rank: x['@_division_rank'],
      games_played: x['@_gp'],
      goals_against: x['@_ga'],
      goals_for: x['@_gf'],
      id: x['@_id'],
      logo: x['@_logo'],
      losses: x['@_l'],
      name: x['@_name'],
      overtime_losses: x['@_otl'],
      overtime_wins: x['@_otw'],
      penalty_in_minutes: x['@_pim'],
      penalty_in_minutes_against: x['@_pima'],
      points: x['@_pts'],
      points_percent: x['@_pts_pct'],
      rank: x['@_rank'],
      shootouts: x['@_so'],
      shootouts_against: x['@_soa'],
      shootouts_losses: x['@_sol'],
      shootouts_wins: x['@_sow'],
      wins: x['@_w'],
      city: x['@_city'],
    };
  });
}

export function mapPlayoffStandingsToReadable(
  standings: Array<PlayoffStandingsDto>
): Array<PlayoffStandingsReadableDto> {
  return standings.map(
    (x: PlayoffStandingsDto): PlayoffStandingsReadableDto => {
      return {
        id: x['@_id'],
        round: x['@_round'],
        round_name: x['@_roundname'],
        teama: x['@_teama'],
        teamb: x['@_teamb'],
        score: x['@_score'],
        teamaCode: x['@_homeName_l3_en'],
        teambCode: x['@_visitorName_l3_en'],
        teamaName: x['@_homeName'],
        teambName: x['@_visitorName'],
        teamaCity: x['@_homeCity'],
        teambCity: x['@_visitorCity'],
        date: x['@_date'],
        time: x['@_time'],
        arena: x['@_arena'],
      };
    }
  );
}

export function getCurrentSeasonYear() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  return currentMonth < 8 ? currentYear : currentYear + 1;
}

export function getSeasonYear(season: string): string {
  return season.slice(0, 4);
}

const sortByCategory = (
  a: PlayerSeasonStatsDtoReadable,
  b: PlayerSeasonStatsDtoReadable,
  category: keyof PlayerSeasonStatsDtoReadable
) => {
  if (!a[category] && !b[category]) {
    return 0;
  }

  if (!a[category]) {
    return -1;
  }

  if (!b[category]) {
    return 1;
  }

  return Number(a[category]) - Number(b[category]);
};

export function getTopPoints(
  playerStats: Array<PlayerSeasonStatsDtoReadable>
): PlayerSeasonStatsDtoReadable | null {
  if (playerStats.length === 0) {
    return null;
  }
  return playerStats.sort(
    (a: PlayerSeasonStatsDtoReadable, b: PlayerSeasonStatsDtoReadable) => {
      let result = sortByCategory(a, b, 'points');
      if (result) {
        return result;
      }

      result = sortByCategory(a, b, 'goals');
      if (result) {
        return result;
      }

      result = sortByCategory(a, b, 'assists');
      if (result) {
        return result;
      }

      return sortByCategory(a, b, 'plus_minus');
    }
  )[playerStats.length - 1 || 0];
}

export function getTopGoals(
  playerStats: Array<PlayerSeasonStatsDtoReadable>
): PlayerSeasonStatsDtoReadable | null {
  if (playerStats.length === 0) {
    return null;
  }
  return playerStats.sort(
    (a: PlayerSeasonStatsDtoReadable, b: PlayerSeasonStatsDtoReadable) =>
      sortByCategory(a, b, 'goals')
  )[playerStats.length - 1 || 0];
}

export function getTopAssists(
  playerStats: Array<PlayerSeasonStatsDtoReadable>
): PlayerSeasonStatsDtoReadable | null {
  if (playerStats.length === 0) {
    return null;
  }
  return playerStats.sort(
    (a: PlayerSeasonStatsDtoReadable, b: PlayerSeasonStatsDtoReadable) =>
      sortByCategory(a, b, 'assists')
  )[playerStats.length - 1 || 0];
}

export function getTopPenalty(
  playerStats: Array<PlayerSeasonStatsDtoReadable>
): PlayerSeasonStatsDtoReadable | null {
  if (playerStats.length === 0) {
    return null;
  }
  return playerStats.sort(
    (a: PlayerSeasonStatsDtoReadable, b: PlayerSeasonStatsDtoReadable) =>
      sortByCategory(a, b, 'penalty_in_minutes')
  )[playerStats.length - 1 || 0];
}

export function getTopSavings(
  playerStats: Array<PlayerSeasonStatsDtoReadable>
): PlayerSeasonStatsDtoReadable | null {
  if (playerStats.length === 0) {
    return null;
  }
  return playerStats.sort(
    (a: PlayerSeasonStatsDtoReadable, b: PlayerSeasonStatsDtoReadable) =>
      sortByCategory(a, b, 'savings')
  )[playerStats.length - 1 || 0];
}

export function getTopGoalsAgainstAverage(
  playerStats: Array<PlayerSeasonStatsDtoReadable>
): PlayerSeasonStatsDtoReadable | null {
  if (playerStats.length === 0) {
    return null;
  }
  const topPlayer = playerStats
    .sort((a: PlayerSeasonStatsDtoReadable, b: PlayerSeasonStatsDtoReadable) =>
      sortByCategory(a, b, 'goals_against_average')
    )
    .filter(
      (player: PlayerSeasonStatsDtoReadable) =>
        player.goals_against_average !== undefined
    )[0];

  if (!topPlayer) {
    const goalkeeper = playerStats
      .filter((player) => player.position === 'в' && player.id)
      .sort((a, b) => Number(a?.id) - Number(b?.id))[0];

    return goalkeeper || playerStats[0];
  }

  return topPlayer || playerStats[0];
}

export async function loadTopPlayerByStats(
  playerStats: PlayerSeasonStatsDtoReadable | null,
  category: string,
  value: number
): Promise<TopPlayerCardType> {
  const resp = await getPlayerByIdLeague(playerStats?.id);

  const topPlayer: TopPlayerCardType = {
    player: resp && resp.data && resp.data[0] ? resp.data[0] : null,
    stats: playerStats || null,
    category: category,
    value: value,
  };

  return topPlayer;
}

export function getNRandomInts(count: number, max: number) {
  const ints: Array<number> = [];
  const maxIndex = count < max ? max : count;

  do {
    let ran = Math.floor(Math.random() * maxIndex);
    if (ints.indexOf(ran) === -1) {
      ints.push(ran);
    }
  } while (ints.length < count);

  return ints;
}

export function compareOrder(a: SubMenuElementDto, b: SubMenuElementDto) {
  if (a.attributes.display_order < b.attributes.display_order) {
    return -1;
  }
  if (a.attributes.display_order > b.attributes.display_order) {
    return 1;
  }

  return 0;
}

export function compareTopPartnersDtoOrder(
  a: TopPartnersDto,
  b: TopPartnersDto
) {
  return a.attributes.order_index - b.attributes.order_index;
}

export function getLastSeasonOfPlayer(player: PlayerWithRelations): string {
  return player.attributes.rosters.data.sort((a: Roster, b: Roster) => {
    if (
      a.attributes.season.data.attributes.name <
      b.attributes.season.data.attributes.name
    ) {
      return 1;
    }

    if (
      a.attributes.season.data.attributes.name >
      b.attributes.season.data.attributes.name
    ) {
      return -1;
    }

    return 0;
  })[0].attributes.season.data.attributes.name;
}

export function sortPartnersDto(a: PartnerDto, b: PartnerDto) {
  if (!a.attributes.priority) {
    return -1;
  }
  if (!b.attributes.priority) {
    return 1;
  }
  return a.attributes.priority - b.attributes.priority;
}

export function sortGoalkeepers(
  a: PlayerSeasonStatsDto,
  b: PlayerSeasonStatsDto
): number {
  if (
    (a['@_gaa'] == 0 ? 0 : Number(a['@_gaa']) || 1000) -
      (b['@_gaa'] == 0 ? 0 : Number(b['@_gaa']) || 1000) !==
    0
  ) {
    return (
      (a['@_gaa'] == 0 ? 0 : Number(a['@_gaa']) || 1000) -
      (b['@_gaa'] == 0 ? 0 : Number(b['@_gaa']) || 1000)
    );
  } else if (
    (a['@_gp'] == 0 ? 0 : Number(a['@_gp']) || 1000) -
      (b['@_gp'] == 0 ? 0 : Number(b['@_gp']) || 1000) !==
    0
  ) {
    return (
      (a['@_gp'] == 0 ? 0 : Number(a['@_gp']) || 1000) -
      (b['@_gp'] == 0 ? 0 : Number(b['@_gp']) || 1000)
    );
  } else {
    return Number(b['@_sv_pct'] || 0) - Number(a['@_sv_pct'] || 0);
  }
}

export function sortForwards(
  a: PlayerSeasonStatsDto,
  b: PlayerSeasonStatsDto
): number {
  if ((Number(b['@_pts']) || 0) - (Number(a['@_pts']) || 0) !== 0) {
    return (Number(b['@_pts']) || 0) - (Number(a['@_pts']) || 0);
  } else if ((Number(b['@_g']) || 0) - (Number(a['@_g']) || 0) !== 0) {
    return (Number(b['@_g']) || 0) - (Number(a['@_g']) || 0);
  } else if ((Number(b['@_a']) || 0) - (Number(a['@_a']) || 0) !== 0) {
    return (Number(b['@_a']) || 0) - (Number(a['@_a']) || 0);
  } else if (
    (a['@_gp'] == 0 ? 0 : Number(a['@_gp']) || 1000) -
      (b['@_gp'] == 0 ? 0 : Number(b['@_gp']) || 1000) !==
    0
  ) {
    return (
      (a['@_gp'] == 0 ? 0 : Number(a['@_gp']) || 1000) -
      (b['@_gp'] == 0 ? 0 : Number(b['@_gp']) || 1000)
    );
  } else if ((Number(b['@_pm']) || 0) - (Number(a['@_pm']) || 0) !== 0) {
    return (Number(b['@_pm']) || 0) - (Number(a['@_pm']) || 0);
  } else {
    return (
      (a['@_pim'] == 0 ? 0 : Number(a['@_pim']) || 1000) -
      (b['@_pim'] == 0 ? 0 : Number(b['@_pim']) || 1000)
    );
  }
}

export function customSort<
  T extends PlayerSeasonStatsDtoReadable | StandingsReadableDto,
>(arr: Array<T>, sortCriteria: Array<SortElement>, isInitial?: boolean) {
  return arr.sort((a: any, b: any) => {
    for (const criteria of sortCriteria) {
      const { key, desc, type } = criteria;

      if (key === 'goals_for') {
        const goalsA = a['goals_for'];
        const goalsLossesA = a['goals_against'];
        const goalsB = b['goals_for'];
        const goalsLossesB = b['goals_against'];

        const resultDiffCompare = compareNumberAndEmptyWithOrdering(
          goalsA,
          goalsB,
          desc
        );

        if (resultDiffCompare === 0) {
          const resultGoalsCompare = compareNumberAndEmptyWithOrdering(
            goalsLossesA,
            goalsLossesB,
            !desc
          );
          if (resultGoalsCompare === 0) {
            continue;
          }
          return resultGoalsCompare;
        }
        return resultDiffCompare;
      }

      if (key === 'namelocal') {
        const aValue = getNameSurname(a);
        const bValue = getNameSurname(b);
        const aValueName = aValue.split(' ')[0];
        const bValueName = bValue.split(' ')[0];
        const aValueSurname = aValue.split(' ')[1];
        const bValueSurname = bValue.split(' ')[1];

        const resultNameCompare = desc
          ? baseAlphaNumericCompare(bValueName, aValueName)
          : baseAlphaNumericCompare(aValueName, bValueName);

        if (resultNameCompare === 0) {
          const resultSurnameCompare = desc
            ? baseAlphaNumericCompare(bValueSurname, aValueSurname)
            : baseAlphaNumericCompare(aValueSurname, bValueSurname);
          if (resultSurnameCompare === 0) {
            continue;
          }
          return resultSurnameCompare;
        }
        return resultNameCompare;
      }

      if (key === 'goals_against_average') {
        const aValue = a['goals_against_average'];
        const bValue = b['goals_against_average'];

        if (isInitial) {
          return compareGoalsAgainstAveradgeWithOrdering(aValue, bValue, desc);
        } else {
          return compareNumberAndEmptyWithOrdering(aValue, bValue, desc);
        }
      }

      let functionOfCompare;
      switch (type) {
        case StandingsTypes.number: {
          functionOfCompare = compareNumberAndEmptyWithOrdering;
          break;
        }
        case StandingsTypes.time: {
          functionOfCompare = compareMinutesAndSecondsWithOrdering;
          break;
        }
        default:
          functionOfCompare = compareStringAndEmptyWithOrdering;
      }
      const resultOfCompare = functionOfCompare(a[key], b[key], desc);

      if (resultOfCompare === 0) {
        continue;
      } else {
        return resultOfCompare;
      }
    }

    return 0;
  });
}

export function isEmpty(value: string | null | undefined | number) {
  return value === undefined || value === null || value.toString().length === 0;
}

function compareDataWithEmptyAndOrdering(a: any, b: any, desc: boolean) {
  if (isEmpty(a) && isEmpty(b)) {
    return 0;
  }
  if (isEmpty(a) && !isEmpty(b)) {
    return desc ? 1 : -1;
  }
  if (!isEmpty(a) && isEmpty(b)) {
    return desc ? -1 : 1;
  }
  return 0;
}

function compareGoalsAgainstAveradgeWithOrdering(
  a: any,
  b: any,
  desc: boolean
) {
  if (isEmpty(a) && isEmpty(b)) {
    return 0;
  }
  if (isEmpty(a) && !isEmpty(b)) {
    return desc ? -1 : 1;
  }
  if (!isEmpty(a) && isEmpty(b)) {
    return -1;
  }
  return desc ? Number(b) - Number(a) : Number(a) - Number(b);
}

function compareStringAndEmptyWithOrdering(
  a: string | null | undefined | number,
  b: string | null | undefined | number,
  desc: boolean
) {
  const resultEmptyCompare = compareDataWithEmptyAndOrdering(a, b, desc);

  if (resultEmptyCompare !== 0) {
    return resultEmptyCompare;
  }

  return desc
    ? baseAlphaNumericCompare(String(b), String(a))
    : baseAlphaNumericCompare(String(a), String(b));
}

function compareNumberAndEmptyWithOrdering(
  a: string | null | undefined | number,
  b: string | null | undefined | number,
  desc: boolean
) {
  a = Number.isNaN(Number(a)) ? undefined : a;
  b = Number.isNaN(Number(b)) ? undefined : b;
  const resultEmptyCompare = compareDataWithEmptyAndOrdering(a, b, desc);

  if (resultEmptyCompare !== 0) {
    return resultEmptyCompare;
  }

  return desc ? Number(b) - Number(a) : Number(a) - Number(b);
}

function compareMinutesAndSecondsWithOrdering(
  a: string | null | undefined,
  b: string | null | undefined,
  desc: boolean
) {
  const timeA = a && a.length > 1 ? a.split(':') : ['0', '0'];
  const timeB = b && b.length > 1 ? b.split(':') : ['0', '0'];
  const minutesA = parseInt(timeA[0]);
  const secondsA = parseInt(timeA[1]);
  const minutesB = parseInt(timeB[0]);
  const secondsB = parseInt(timeB[1]);

  if (minutesA === minutesB) {
    return desc ? secondsB - secondsA : secondsA - secondsB;
  }
  return desc ? minutesB - minutesA : minutesA - minutesB;
}

export function baseAlphaNumericCompare(
  a: string | number,
  b: string | number
) {
  const stringA = (a && a.toString().replace(/\(|\)/g, ' ')) || '';
  const stringB = (b && b.toString().replace(/\(|\)/g, ' ')) || '';

  return stringA.localeCompare(stringB, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

export function sortByKey<
  T extends PlayerSeasonStatsDtoReadable | StandingsReadableDto,
>(items: Array<T>, key: string, asc: boolean): T[] {
  items.sort((a: any, b: any) => {
    if (a[key] === b[key]) {
      if (asc) {
        return (
          baseAlphaNumericCompare(a.points, b.points) ||
          baseAlphaNumericCompare(a.wins, b.wins) ||
          baseAlphaNumericCompare(a.overtime_wins, b.overtime_wins) ||
          baseAlphaNumericCompare(a.shootouts_wins, b.shootouts_wins) ||
          baseAlphaNumericCompare(a.shootouts_losses, b.shootouts_losses) ||
          baseAlphaNumericCompare(a.overtime_losses, b.overtime_losses) ||
          baseAlphaNumericCompare(a.losses, b.losses) ||
          baseAlphaNumericCompare(
            a.goals_for - a.goals_against,
            b.goals_for - b.goals_against
          )
        );
      } else {
        return (
          baseAlphaNumericCompare(b.points, a.points) ||
          baseAlphaNumericCompare(b.wins, a.wins) ||
          baseAlphaNumericCompare(b.overtime_wins, a.overtime_wins) ||
          baseAlphaNumericCompare(b.shootouts_wins, a.shootouts_wins) ||
          baseAlphaNumericCompare(b.shootouts_losses, a.shootouts_losses) ||
          baseAlphaNumericCompare(b.overtime_losses, a.overtime_losses) ||
          baseAlphaNumericCompare(
            b.goals_for - b.goals_against,
            a.goals_for - a.goals_against
          )
        );
      }
    } else {
      return asc
        ? baseAlphaNumericCompare(a[key], b[key])
        : baseAlphaNumericCompare(b[key], a[key]);
    }
  });
  return items;
}

export function sortByKeyPlayers<
  T extends PlayerSeasonStatsDtoReadable | StandingsReadableDto,
>(items: Array<T>, key: string, asc: boolean, isGoalkeepers: boolean): T[] {
  isGoalkeepers
    ? items.sort((a: any, b: any) =>
        a[key] === b[key]
          ? asc
            ? baseAlphaNumericCompare(
                b.goals_against_average,
                a.goals_against_average
              ) ||
              baseAlphaNumericCompare(b.games_played, a.games_played) ||
              baseAlphaNumericCompare(a.savings_percent, b.savings_percent)
            : baseAlphaNumericCompare(
                a.goals_against_average,
                b.goals_against_average
              ) ||
              baseAlphaNumericCompare(a.games_played, b.games_played) ||
              baseAlphaNumericCompare(b.savings_percent, a.savings_percent)
          : asc
          ? key === 'goals_against_average' &&
            a.goals_against_average &&
            b.goals_against_average
            ? baseAlphaNumericCompare(b[key], a[key])
            : baseAlphaNumericCompare(a[key], b[key])
          : key === 'goals_against_average' &&
            a.goals_against_average &&
            b.goals_against_average
          ? baseAlphaNumericCompare(a[key], b[key])
          : baseAlphaNumericCompare(b[key], a[key])
      )
    : items.sort((a: any, b: any) =>
        a[key] === b[key]
          ? asc
            ? baseAlphaNumericCompare(a.points, b.points) ||
              baseAlphaNumericCompare(a.goals, b.goals) ||
              baseAlphaNumericCompare(a.assists, b.assists) ||
              baseAlphaNumericCompare(b.games_played, a.games_played) ||
              baseAlphaNumericCompare(
                b.penalty_in_minutes,
                a.penalty_in_minutes
              )
            : baseAlphaNumericCompare(b.points, a.points) ||
              baseAlphaNumericCompare(b.goals, a.goals) ||
              baseAlphaNumericCompare(b.assists, a.assists) ||
              baseAlphaNumericCompare(a.games_played, b.games_played) ||
              baseAlphaNumericCompare(
                a.penalty_in_minutes,
                b.penalty_in_minutes
              )
          : asc
          ? baseAlphaNumericCompare(a[key], b[key])
          : baseAlphaNumericCompare(b[key], a[key])
      );
  return items;
}

export function sortByKeyGoalkeeper<
  T extends PlayerSeasonStatsDtoReadable | StandingsReadableDto,
>(items: Array<T>, key: string, asc: boolean): T[] {
  items.sort((a: any, b: any) =>
    a[key] === b[key]
      ? asc
        ? baseAlphaNumericCompare(
            b.goals_against_average,
            a.goals_against_average
          ) ||
          baseAlphaNumericCompare(b.games_played, a.games_played) ||
          baseAlphaNumericCompare(a.savings_percent, b.savings_percent)
        : baseAlphaNumericCompare(
            a.goals_against_average,
            b.goals_against_average
          ) ||
          baseAlphaNumericCompare(a.games_played, b.games_played) ||
          baseAlphaNumericCompare(b.savings_percent, a.savings_percent)
      : asc
      ? baseAlphaNumericCompare(a[key], b[key])
      : baseAlphaNumericCompare(b[key], a[key])
  );
  return items;
}

export function sortByKeyPoints(
  items: Array<StandingsReadableDto>,
  key_id: number,
  asc: boolean,
  keys: string[]
) {
  items.sort((a: any, b: any) =>
    a[keys[key_id]] === b[keys[key_id]] && key_id !== 0
      ? asc
        ? baseAlphaNumericCompare(a[keys[key_id - 1]], b[keys[key_id - 1]])
        : baseAlphaNumericCompare(b[keys[key_id - 1]], a[keys[key_id - 1]])
      : asc
      ? baseAlphaNumericCompare(a[keys[key_id]], b[keys[key_id]])
      : baseAlphaNumericCompare(b[keys[key_id]], a[keys[key_id]])
  );

  return items;
}

export function sortByKeyGoals(
  items: Array<StandingsReadableDto>,
  asc: boolean
) {
  items.sort((a: any, b: any) =>
    a['goals_for'] === b['goals_for']
      ? asc
        ? baseAlphaNumericCompare(
            a['goals_for'] - a['goals_against'],
            b['goals_for'] - b['goals_against']
          )
        : baseAlphaNumericCompare(
            b['goals_for'] - b['goals_against'],
            a['goals_for'] - a['goals_against']
          )
      : asc
      ? baseAlphaNumericCompare(a['goals_for'], b['goals_for'])
      : baseAlphaNumericCompare(b['goals_for'], a['goals_for'])
  );
  return items;
}

export function getNoun(
  number: number,
  one: string,
  two: string,
  five: string
) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

export function parseISO8601Duration(durationString: string) {
  const regex =
    /P(?:([\d.,]+)Y)?(?:([\d.,]+)M)?(?:([\d.,]+)W)?(?:([\d.,]+)D)?(?:T(?:([\d.,]+)H)?(?:([\d.,]+)M)?(?:([\d.,]+)S)?)?/;
  const match = durationString.match(regex);

  if (!match) {
    throw new Error('Invalid ISO 8601 duration format');
  }

  const [, years, months, weeks, days, hours, minutes, seconds] =
    match.map(parseFloat);

  const durationObject = {
    years: years || 0,
    months: months || 0,
    weeks: weeks || 0,
    days: days || 0,
    hours: hours || 0,
    minutes: minutes || 0,
    seconds: seconds || 0,
  };

  return durationObject;
}

export function getAge(birth: Date) {
  const today = new Date();
  const birthDate = new Date(birth);
  return differenceInYears(today, birthDate);
}

export function getYearPostfix(year: number): string {
  const lastDigit = year % 10;
  const lastTwoDigits = year % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return 'лет';
  }

  switch (lastDigit) {
    case 1:
      return 'год';
    case 2:
    case 3:
    case 4:
      return 'года';
    default:
      return 'лет';
  }
}

export function formatYearFromDate(dateInput: string | Date): string {
  const date = new Date(dateInput);
  return `с ${date.getFullYear()}`;
}

export function getDate(dateDb: Date | undefined) {
  if (!dateDb) {
    return '';
  }

  const date = new Date(dateDb);
  return `${getTwoDigitNumber(date.getDate().toString())}.${getTwoDigitNumber(
    (date.getMonth() + 1).toString()
  )}.${date.getFullYear()}`;
}

export function getBeginningOfTheYear(year: number | string) {
  return `${year}-01-01T00:00:00Z`;
}

export function getEndOfTheYear(year: number | string) {
  return `${year}-12-31T23:59:59Z`;
}

export function getHclYears(isMHL?: boolean) {
  const currentYear = new Date().getFullYear();
  const hclYears = [];
  for (
    let i = isMHL ? starts_mhl_year : starts_hcl_year;
    i < currentYear + 1;
    i++
  ) {
    hclYears.push(i);
  }
  return hclYears;
}

export function getLatestBooklet(booklets: Array<Article>) {
  if (!booklets.length) return null;

  return booklets.reduce((latest, current) =>
    current.attributes.date > latest.attributes.date ? current : latest
  );
}
export const getFullExecutiveName = (
  executive: ExecutiveDto | undefined,
  isSurnameIncluded: boolean = true
): string => {
  if (!executive) {
    return '';
  }

  const name = executive?.attributes.name ?? '';
  const surname = executive?.attributes.surname ?? '';
  const patronymic = executive?.attributes?.patronymic ?? '';

  return `${name} ${patronymic} ${isSurnameIncluded ? surname : ''}`;
};

export function getScore(score: number) {
  return score === 0 ? '0' : score || '-';
}

export function getDateWithDayOfWeekCommon(date: string | Date) {
  const today = new Date();
  const newDate = new Date(date);

  if (
    today.getDate() === newDate.getDate() &&
    today.getMonth() === newDate.getMonth() &&
    today.getFullYear() === newDate.getFullYear()
  ) {
    const dateInfo = newDate.toLocaleTimeString('ru-RU', {
      hour: 'numeric',
      minute: 'numeric',
    });
    return `Cегодня, ${dateInfo}`;
  } else {
    const dateInfo = newDate.toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
    if (dateInfo !== 'Invalid Date')
      return `${DAYS_OF_WEEK.get(newDate.getDay())}, ${dateInfo}`;
  }
}

export function getYears(seasonParam: string) {
  return `${Number(seasonParam.slice(0, 4)) - 1}/${Number(
    seasonParam.slice(2, 4)
  )}`;
}

function getYear(seasonParam: string) {
  return Number(seasonParam.slice(0, 4));
}

export function getSeasonName(seasonParam: string) {
  const year = getYears(seasonParam);
  const seasonNameYear = getYear(seasonParam);
  if (seasonParam.endsWith('MEN')) return `Регулярный чемпионат ${year}`;
  if (seasonParam.endsWith('MEN-2')) return `Кубок Гагарина ${year}`;
  if (seasonParam.endsWith('LKO')) {
    if (seasonNameYear <= 2021) {
      return `«МХЛ. Регулярный чемпионат ${year}`;
    } else {
      return `«Локо». Регулярный чемпионат ${year}`;
    }
  }
  if (seasonParam.endsWith('LKO-2')) return `Кубок Харламова ${year}`;
  if (seasonParam.endsWith('L76'))
    return `«Локо-76». Регулярный чемпионат ${year}`;
  if (seasonParam.endsWith('L76-2')) return `Кубок Харламова ${year}`;
  return '';
}

export function getPreviousMonth(month: number) {
  const currentMonth = new Date().getMonth();
  if (month === 12) {
    return currentMonth === 0 ? 11 : currentMonth - 1;
  }
  return month === 0 ? 11 : month - 1;
}

export function getNextMonth(month: number) {
  const currentMonth = new Date().getMonth();
  if (month === 12) {
    return currentMonth === 11 ? 0 : currentMonth + 1;
  }
  return month === 11 ? 0 : month + 1;
}

export function loadHightResolutionImage(
  url: string,
  callback: (url: string) => void
): void {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    callback(url);
  };
}

export function getDataForPoint(
  point: ArenaPointsType,
  data?: Array<ArenaAboutObject>
) {
  let pointData: {
    title: string;
    images: Array<ImageDto>;
    description: string;
  } = {
    title: '',
    images: [],
    description: '',
  };

  if (data && data.length > 0) {
    const matchedData = data.find(
      (item) => item.attributes.sys_name === point.alt
    );

    if (matchedData) {
      pointData = {
        title: matchedData?.attributes?.title ?? '',
        images: matchedData?.attributes?.images?.data || [],
        description: matchedData?.attributes?.description ?? '',
      };
    }
  }

  return pointData;
}

export function checkScore(score: number) {
  if (score) return true;
  if (score === 0) return true;
  return false;
}

export function getDateForPhoto(date: Date) {
  const currentDate = new Date(date);
  return `${currentDate.getDate()} ${MONTHS.get(
    currentDate.getMonth()
  )} ${currentDate.getFullYear()}`;
}

export function getTwoDigitNumber(number: string) {
  return ('0' + number).slice(-2);
}

export const validateEmail = (email: string) => {
  return String(email).toLowerCase().match(Regexp.emailValidation);
};

export function getDateDebut(debut: Date) {
  const debutDate = new Date(debut);
  return `${t('playerPage.debutDate', {
    year: debutDate.getFullYear(),
  })}
  `;
}

export function addTargetBlank(str: string) {
  const htmlObject = document.createElement('div');
  htmlObject.innerHTML = str;
  const links = htmlObject.getElementsByTagName('a');
  for (let i = 0; i < links.length; i++) {
    links[i].setAttribute('target', '_blank');
  }
  return htmlObject.innerHTML;
}

// цифры - латинские буквы - русские буквы
export function customLocaleCompare(a: string, b: string) {
  function getFirstCharWeight(str: string) {
    const firstChar = str.charAt(0);
    if (firstChar.match(/\d/)) {
      return 0; // Вес для цифр
    } else if (firstChar.match(/[a-zA-Z]/)) {
      return 10; // Вес для латинских букв
    } else if (firstChar.match(/[а-яА-ЯёЁ]/)) {
      return 20; // Вес для русских букв
    } else {
      return 30; // Вес для всех остальных символов
    }
  }

  const weightA = getFirstCharWeight(a);
  const weightB = getFirstCharWeight(b);

  if (weightA !== weightB) {
    return weightA - weightB;
  } else return a.localeCompare(b);
}

export function getLastSeasonForExecutive(
  executive: ExecutiveDto
): string | undefined {
  return executive?.attributes?.executive_rosters?.data.sort(
    (a: ExecutiveRosterDto, b: ExecutiveRosterDto) => {
      if (
        a.attributes.season.data.attributes.name <
        b.attributes.season.data.attributes.name
      ) {
        return 1;
      }

      if (
        a.attributes.season.data.attributes.name >
        b.attributes.season.data.attributes.name
      ) {
        return -1;
      }

      return 0;
    }
  )[0].attributes.season.data.attributes.name;
}

export function sortSeasonOptions(a: string, b: string) {
  const aMain = a.slice(0, 7);
  const bMain = b.slice(0, 7);
  if (aMain !== bMain) {
    return bMain.localeCompare(aMain);
  } else {
    const aPostfix = a.slice(7);
    const bPostfix = b.slice(7);

    if (aPostfix === bPostfix) {
      return 0;
    }

    if (bPostfix === '-prip') {
      return -1;
    }

    if (bPostfix === '') {
      if (aPostfix === '-prip') {
        return 1;
      }

      if (aPostfix === '-2') {
        return -1;
      }
    }

    return 1;
  }
}

export default async function SetRespError(
  error: any,
  setIsNotFoundError: any,
  setIsError: any
) {
  // Add `else if` for more custom pages or replace with `switch`. Add set<...> parameter function for each.
  if (error?.response?.status === 404) {
    setIsNotFoundError(true);
  } else {
    setIsError(true);
  }

  console.error(error?.response?.data?.error || error?.message);
}

export const decodeHTMLEntities = (str: string) => {
  return str.replace(/&quot;|&#039;/g, '"').replace(/&#39;/g, "'");
};

export const previewOfVideo = (video: VKVideosVideo): string => {
  return video.image.reduce(
    (result: VKVideosVideoImage, image: VKVideosVideoImage) => {
      if (image.width > 0 || image.height > 0) return image;

      return result;
    },
    {
      url: '',
      width: 0,
      height: 0,
    }
  ).url;
};
