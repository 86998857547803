import { Tooltip } from 'antd';
import { ReactElement } from 'react';
import playIconActive from '../../../../../assets/penalty-play-icon-active.svg';
import playIcon from '../../../../../assets/penalty-play-icon.svg';
import {
  GameProtocolDto,
  GameWithLogo,
  Highlight,
} from '../../../../../types/Types';
import styles from './PenaltyTable.module.scss';
import {
  HighlightPenaltyPair,
  PenaltyData,
} from '../../GameProtocolPage.types';

type PenaltyTableProps = {
  protocol: GameProtocolDto;
  game: GameWithLogo;
  penaltyHighlights: Highlight[];
  setSelectedHighlight: (highlight: Highlight) => void;
  penalties: PenaltyData[];
  totalOvertimes: number;
  pairData: HighlightPenaltyPair[];
};

function PenaltyTable({
  setSelectedHighlight,
  totalOvertimes,
  penalties,
  pairData,
}: PenaltyTableProps): ReactElement {
  const renderPeriodTable = (period: number) => {
    const periodPenalties = penalties
      ? penalties.filter((item) => item.period === period.toString())
      : [];

    if (!periodPenalties?.length) {
      return null;
    }

    return (
      <table className={styles.table} key={period}>
        <tbody className={styles.tableBody}>
          <tr className={styles.tableHeader}>
            <th title={'Период'}>
              {period >= 4
                ? totalOvertimes > 1
                  ? `ОТ${period - 3}`
                  : 'ОТ'
                : `${period}-й`}
            </th>
            <th title={'Команда'}>Команда</th>
            <th title={'Удаления'} className={styles.deletions}>
              Удаления
            </th>
            <th></th>
          </tr>
          {periodPenalties.map((item, index) => {
            const highlight = pairData.find((pair) => pair.penalty === item)
              ?.highlight;
            return (
              <tr
                className={styles.tableRow}
                key={index}
                onClick={() => {
                  if (highlight) setSelectedHighlight(highlight);
                }}
              >
                <td>
                  <div className={styles.time}>
                    <img
                      className={styles.playIcon}
                      src={!!highlight ? playIconActive : playIcon}
                      alt=''
                    />
                    <div>{item.time}</div>
                  </div>
                </td>
                <td>
                  <img className={styles.image} src={item.team.logo} alt='' />
                </td>
                <td>
                  <div className={styles.nameAndReason}>
                    <div className={styles.name}>{item.playerName}</div>
                    <Tooltip placement='top' title={item.reason}>
                      <div className={styles.reason}>{item.reason}</div>
                    </Tooltip>
                  </div>
                </td>
                <td>
                  <div className={styles.duration}>{`(${item.duration})`}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const render = () => {
    if (penalties) {
      const periods = Array.from(
        new Set(penalties.map((penalty) => parseInt(penalty.period)))
      ).sort((a, b) => a - b);

      return periods.map((item) => renderPeriodTable(item));
    }

    return [];
  };

  return (
    <div>
      <div className={styles.header}>Штрафы</div>
      {render()}
    </div>
  );
}

export default PenaltyTable;
