import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import SetRespError from 'helpers/helpers';
import { mapPlayerSeasonStatsDto } from 'mapping/mapping';
import { ReactElement, useEffect, useState } from 'react';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getDefaultImages,
  getPlayerById,
  getPlayerSeasonStats,
} from '../../api/api';
import { DEFAULT_IMAGE_NAME, POSITION_MAP } from '../../constants/constants';
import { getLastSeasonOfPlayer } from '../../helpers/helpers';
import {
  DefaultImageObject,
  PlayerSeasonStats,
  PlayerWithRelations,
  Roster,
} from '../../types/Types';
import '../Album/CustomImageGallery.scss';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import InfoWrapper from '../Wrappers/InfoWrapper/InfoWrapper';
import PlayerCardInfo from './PlayerCardInfo/PlayerCardInfo';
import styles from './PlayerPage.module.scss';
import PlayerStartInfo from './PlayerStartInfo/PlayerStartInfo';
import PlayerTabsWithInfo from './PlayerTabsWithInfo/PlayerTabsWithInfo';

const PlayerPage = (): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { playerId } = useParams();
  const [player, setPlayer] = useState<PlayerWithRelations>();
  const [playerRoster, setPlayerRoster] = useState<Roster>();
  const [defaultImages, setDefaultImages] = useState<DefaultImageObject[]>([]);
  const [isNotFoundError, setIsNotFoundError] = useState<boolean>(false);
  const [isPlayerError, setIsPlayerError] = useState<boolean>(false);
  const seasonParam = searchParams.get('season');
  const [playerStats, setPlayerStats] = useState<PlayerSeasonStats>();

  useEffect(() => {
    const getPlayerInfo = async () => {
      try {
        const resp = await getPlayerById(Number(playerId));
        if (!checkPlayerPlayedInSeason(resp.data)) {
          searchParams.set('season', getLastSeasonOfPlayer(resp.data));
          setSearchParams(searchParams);
        }

        setPlayer(resp?.data);
        getCurrentSeasonStatistic(resp.data);
      } catch (e: any) {
        SetRespError(e, setIsNotFoundError, setIsPlayerError);
      }

      try {
        const images = await getDefaultImages();
        setDefaultImages(images.data);
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };
    getPlayerInfo();
  }, []);

  useEffect(() => {
    setPlayerRoster(
      player?.attributes.rosters.data.find(
        (roster) =>
          roster?.attributes?.season?.data?.attributes?.name === seasonParam
      )
    );
  }, [player]);

  const getCurrentSeasonStatistic = async (player: PlayerWithRelations) => {
    if (player?.attributes?.id_league_web) {
      const respStats = await getPlayerSeasonStats(
        player.attributes.id_league_web,
        seasonParam || ''
      );
      setPlayerStats(mapPlayerSeasonStatsDto(respStats));
    }
  };

  function checkPlayerPlayedInSeason(player: PlayerWithRelations): boolean {
    if (!seasonParam) {
      return false;
    }

    const seasons = player.attributes.rosters.data.map(
      (roster) => roster?.attributes?.season?.data?.attributes?.name
    );
    return seasons.includes(seasonParam);
  }

  return (
    <ImageBgPageWrapper
      imageInfo={{
        img_url:
          player?.attributes?.main_bg_photo?.data?.attributes?.url ||
          player?.attributes?.bg_photo?.data?.attributes?.url ||
          defaultImages?.find(
            (x) => x.attributes.description === DEFAULT_IMAGE_NAME
          )?.attributes?.image?.data?.attributes?.url ||
          '',
        image:
          player?.attributes?.main_bg_photo?.data ||
          defaultImages?.find(
            (x) => x.attributes.description === DEFAULT_IMAGE_NAME
          )?.attributes?.image?.data,
        gradient_height: 95, // настроить высоту градиента
        image_height: 1000, // Настроить высоту картинки
      }}
    >
      <div className={styles.playerStartInfo}>
        <PlayerStartInfo
          position={POSITION_MAP.get(player?.attributes?.position?.data?.id)}
          name={player?.attributes?.name || ''}
          surname={player?.attributes?.surname || ''}
          number={
            playerRoster?.attributes?.number?.toString() ||
            playerStats?.number?.toString() ||
            ''
          }
          info={player?.attributes.annotation || ''}
          id={player?.id || 0}
        />
        {player && playerRoster && defaultImages && (
          <PlayerCardInfo
            player={player}
            playerRoster={playerRoster}
            images={defaultImages}
          />
        )}
      </div>

      <div className={styles.wrapper}>
        <InfoWrapper isWhiteBackground={true}>
          {isNotFoundError ? (
            <ErrorWidget.NotFound />
          ) : isPlayerError ? (
            <ErrorWidget.Error />
          ) : (
            player && <PlayerTabsWithInfo player={player} />
          )}
        </InfoWrapper>
      </div>
    </ImageBgPageWrapper>
  );
};

export default PlayerPage;
