import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactImageGallery from 'react-image-gallery';
import { getPaginationPhotos, getPhotos } from '../../../../api/api';
import { useResponsiveFlags } from '../../../../hooks/useResponsiveFlags';
import { AlbumSimple, File } from '../../../../types/Types';
import Spinner from '../../../Spinner/Spinner';
import ViewMoreButton from '../../../ViewMoreButton/ViewMoreButton';
import PlayerPhoto from '../../../PlayerPage/PlayerPhoto/PlayerPhoto';
import styles from './SubTab.module.scss';

type PhotosSubTabProps = {
  searchString?: string;
};

const PhotosSubTab: FC<PhotosSubTabProps> = ({ searchString }) => {
  const { isDesktop, isTablet, isMobile } = useResponsiveFlags();
  const { t } = useTranslation();
  const ALBUM_PAGE_AMOUNT = !isDesktop ? 8 : 9;
  const [isLastFetchPhotos, setIsLastFetchPhotos] = useState(false);
  const [topPhotos, setTopPhotos] = useState<File[]>([]);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(true);
  const [pageCount, setPageCount] = useState<number>(ALBUM_PAGE_AMOUNT);

  const [startIndexAlbum, setStartIndexAlbum] = useState<number>(0);
  const [album, setAlbum] = useState<AlbumSimple>();
  const [isOpenAlbum, setIsOpenAlbum] = useState(false);
  const galleryRef = useRef<ReactImageGallery | null>(null);

  async function openAlbum(startIndex?: number) {
    if (searchString) {
      try {
        const photos = await getPhotos(searchString, -1);
        const albumTmp = {
          description: searchString ?? '',
          photo: photos.map((photo: File) => photo.url),
        };
        setStartIndexAlbum(startIndex || 0);
        setAlbum(albumTmp);
        setIsOpenAlbum(true);
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    }
  }

  useEffect(() => {
    if (isOpenAlbum && album?.photo && galleryRef?.current) {
      galleryRef.current?.fullScreen();
    }
  }, [album?.photo, galleryRef, isOpenAlbum]);

  const handleViewMoreButtonClick = () => {
    setPageCount(pageCount + ALBUM_PAGE_AMOUNT);
  };

  useEffect(() => {
    setIsLoadingPhotos(true);
    const getTopPhotos = async () => {
      try {
        if (searchString) {
          const photos = await getPaginationPhotos(searchString, pageCount);
          setTopPhotos(photos);
          const checkFetch = photos;
          if (checkFetch.length === topPhotos.length)
            setIsLastFetchPhotos(true);
        }
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      } finally {
        setIsLoadingPhotos(false);
      }
    };
    getTopPhotos();
  }, [searchString, pageCount]);

  return (
    <>
      <div
        className={classNames(styles.photosGrid, {
          [styles.photosGridThreeColumns]: isDesktop,
          [styles.photosGridTwoColumns]: isTablet,
          [styles.photosGridOneColumn]: isMobile,
        })}
      >
        {topPhotos.length !== 0
          ? topPhotos.map((image, index) => (
              <PlayerPhoto
                image={image}
                openAlbum={openAlbum}
                index={index}
                key={image.id}
              />
            ))
          : !isLoadingPhotos && (
              <div className={styles.noPhotos}>
                {t('playerPage.tabs.nothingHereYet')}
              </div>
            )}
      </div>

      {isLoadingPhotos ? (
        <Spinner />
      ) : topPhotos.length % 9 !== pageCount % 9 || isLastFetchPhotos ? (
        <></>
      ) : (
        <ViewMoreButton onClick={() => handleViewMoreButtonClick()} />
      )}

      {isOpenAlbum && album && (
        <ReactImageGallery
          items={
            album.photo
              ? album.photo?.map((url) => {
                  return {
                    original: url,
                    thumbnail: url,
                    originalClass: !isOpenAlbum ? 'customImage' : '',
                    thumbnailClass: 'customThumbnail',
                  };
                })
              : []
          }
          infinite={false}
          showPlayButton={false}
          additionalClass='gallery'
          onScreenChange={(value: boolean) => {
            setIsOpenAlbum(value);
          }}
          startIndex={startIndexAlbum || 0}
          ref={galleryRef}
        />
      )}
    </>
  );
};

export default PhotosSubTab;
