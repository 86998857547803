import { getVkVideos } from 'api/api';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import PageHeader from 'components/common/PageHeader/PageHeader';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import Spinner from 'components/Spinner/Spinner';
import ViewMoreButton from 'components/ViewMoreButton/ViewMoreButton';
import Colors from 'constants/colors';
import Url from 'constants/urls';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from '../../assets/upper_right_arrow.svg';
import i18n from '../../i18n';
import { VKVideosVideo } from '../../types/Types';
import Container from '../common/Container/Container';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import SideMenu from '../common/SideMenu/SideMenu';
import VideoModal from '../VideoModal/VideoModal';
import styles from './LokoTVPage.module.scss';
import VideoItem from './VideoItem/VideoItem';
import YearVideoFilter from './YearVideoFilter/YearVideoFilter';
import { previewOfVideo } from 'helpers/helpers';

const PAGE_SIZE = 24;

const formatViews = (
  count: number
): {
  value: number;
  multiplier: string;
} => {
  if (count >= 1_000_000_000) {
    return {
      value: Number((count / 1_000_000_000).toFixed(1)),
      multiplier: 'billions',
    };
  }
  if (count >= 1_000_000) {
    return {
      value: Number((count / 1_000_000).toFixed(1)),
      multiplier: 'millions',
    };
  }
  if (count >= 1_000) {
    return {
      value: Number((count / 1_000).toFixed(1)),
      multiplier: 'thousands',
    };
  }
  return { value: count, multiplier: 'units' };
};

function formatTimeAgo(date: Date, baseDate: Date, locale = 'ru') {
  const rtf = new Intl.RelativeTimeFormat(locale, {
    numeric: 'auto',
    style: 'short',
  });

  const units: [Intl.RelativeTimeFormatUnit, number][] = [
    ['year', differenceInYears(date, baseDate)],
    ['month', differenceInMonths(date, baseDate)],
    ['week', differenceInWeeks(date, baseDate)],
    ['day', differenceInDays(date, baseDate)],
    ['hour', differenceInHours(date, baseDate)],
    ['minute', differenceInMinutes(date, baseDate)],
    ['second', differenceInSeconds(date, baseDate)],
  ];

  for (const [unit, value] of units) {
    if (Math.abs(value) >= 1) {
      return rtf.format(Math.round(value), unit);
    }
  }

  return rtf.format(0, 'second');
}

const LokoTvPage = (): ReactElement => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState<VKVideosVideo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [yearSelected, setYearSelected] = useState<number>();

  const [selectedVideo, setSelectedVideo] = useState<VKVideosVideo>();
  const [total, setTotal] = useState<number>(0);

  const isEnd = videos.length >= total;

  const lang = i18n.resolvedLanguage === 'ru' ? 'ru' : 'en';

  useEffect(() => {
    const getVideos = async () => {
      try {
        const vkVideo = await getVkVideos(0, PAGE_SIZE + 1, yearSelected);

        const videoData = vkVideo.data.map((entry) => entry.attributes.json);
        setVideos(videoData);
        setTotal(
          (vkVideo.meta.pagination as { total: number }).total as number
        );
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getVideos();
  }, [yearSelected]);

  const loadMoreVideos = async () => {
    const vkVideo = await getVkVideos(videos.length, PAGE_SIZE, yearSelected);

    const videoData = vkVideo.data.map((entry) => entry.attributes.json);
    setVideos([...videos, ...videoData]);
    setTotal((vkVideo.meta.pagination as { total: number }).total as number);
  };

  const onClose = () => {
    setSelectedVideo(undefined);
  };

  const renderContent = () => {
    if (isLoading) return <Spinner />;
    if (isError) return <ErrorWidget.Error />;

    return (
      <>
        <YearVideoFilter
          value={yearSelected}
          onSelect={(year) => setYearSelected(year)}
        />
        <div>
          {videos && videos.length > 0 && (
            <VideoItem
              title={videos[0].title}
              description={videos[0].description}
              imageSrc={previewOfVideo(videos[0])}
              onClick={() => setSelectedVideo(videos[0])}
              countersText={
                t(
                  `lokoTvPage.views.${formatViews(videos[0].views).multiplier}`,
                  { count: formatViews(videos[0].views).value }
                ) +
                ' · ' +
                formatTimeAgo(new Date(videos[0].date * 1000), new Date(), lang)
              }
              isMain
            />
          )}
        </div>

        <div className={styles.videoList}>
          {videos &&
            videos.length > 0 &&
            videos.map(
              (video, index) =>
                video !== videos[0] && (
                  <VideoItem
                    key={`${video.id}_${index}`}
                    title={video.title}
                    description={video.description}
                    imageSrc={previewOfVideo(video)}
                    onClick={() => setSelectedVideo(video)}
                    countersText={
                      t(
                        `lokoTvPage.views.${
                          formatViews(video.views).multiplier
                        }`,
                        { count: formatViews(video.views).value }
                      ) +
                      ' · ' +
                      formatTimeAgo(
                        new Date(video.date * 1000),
                        new Date(),
                        lang
                      )
                    }
                  />
                )
            )}
        </div>
        <div className={styles.ScrollToTopButtonWrapper}>
          <ScrollToTopButton />
        </div>
        {!isEnd ? (
          <div className={styles.buttonWrapper}>
            <ViewMoreButton onClick={loadMoreVideos} />
          </div>
        ) : (
          <div className={styles.buttonWrapper}>
            <a
              className={styles.youtubeLinkButton}
              href={Url.socials.VK_VIDEO}
              target='_blank'
              rel='noreferrer'
            >
              <div className={styles.text}>
                {t('lokoTvPage.navigateToVKVideo')}
              </div>
              <Arrow color={Colors.PRIMARY_RED} />
            </a>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <ImageBgPageWrapper>
        <Container paddingTop='50px'>
          <BreadcrumbsWrapper
            breadcrumbs={[
              {
                name: t('lokoTvPage.breadcrumb'),
                url: '/lokotv',
              },
            ]}
          />
          <div className={styles.contentWrapper}>
            <PageHeader text={t('lokoTvPage.header')} />
          </div>
          <div className={styles.wrapper}>
            <div className={styles.content}>{renderContent()}</div>
            <div className={styles.sideMenu}>
              <SideMenu />
            </div>
          </div>
        </Container>
      </ImageBgPageWrapper>
      <VideoModal
        isVisible={!!selectedVideo}
        videoSource={selectedVideo?.player ?? ''}
        close={onClose}
        isIFrame
      />
    </>
  );
};

export default LokoTvPage;
