import classNames from 'classnames';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MediaTab.module.scss';
import VideosSubTab from './SubTab/VideosSubTab';
import PhotosSubTab from './SubTab/PhotosSubTab';

type MediaTabProps = {
  name?: string;
  surname?: string;
};

const MediaTab: FC<MediaTabProps> = ({ name, surname }) => {
  const { t } = useTranslation();
  const [activeMediaTab, setActiveMediaTab] = useState<number>(0);

  return (
    <>
      <div className={styles.mediaTabs}>
        <button
          onClick={() => setActiveMediaTab(0)}
          className={classNames(styles.mediaTab, {
            [styles.mediaTabActive]: activeMediaTab === 0,
          })}
        >
          {t('playerPage.tabs.photo')}
        </button>
        <button
          onClick={() => setActiveMediaTab(1)}
          className={classNames(styles.mediaTab, {
            [styles.mediaTabActive]: activeMediaTab === 1,
          })}
        >
          {t('playerPage.tabs.video')}
        </button>
      </div>
      <div>
        {activeMediaTab === 0 ? (
          <PhotosSubTab searchString={`${name?.trim()} ${surname?.trim()}`} />
        ) : (
          <VideosSubTab name={name} surname={surname} />
        )}
      </div>
    </>
  );
};

export default MediaTab;
