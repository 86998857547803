import { ReactElement, useEffect, useState } from 'react';
import styles from './GameStatsTable.module.scss';
import {
  GameProtocolDto,
  GameWithLogo,
  PlayerDto,
  ProtocolTeamDatawithPlayerStats,
} from 'types/Types';
import {
  getDateWithDayOfWeekCommon,
  mapPlayersToReadable,
} from 'helpers/helpers';
import PlayerStatsTable from './PlayerStatsTable/PlayerStatsTable';
import TeamStatsTable from './TeamStatsTable/TeamStatsTable';
import ShowOn from 'components/core/adaptivity/ShowOn';
import classNames from 'classnames';
import { listPlayers } from 'api/api';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import Spinner from 'components/Spinner/Spinner';

type GameTableProps = {
  protocol: GameProtocolDto;
  game: GameWithLogo;
};

const GameStatsTable = observer(
  ({ protocol, game }: GameTableProps): ReactElement => {
    const [teams, setTeams] = useState<ProtocolTeamDatawithPlayerStats[]>();
    const [activeTab, setActiveTab] = useState<number>(0);
    const { lokomotivTeamsStore } = useStore();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const loadData = async () => {
        if (
          !lokomotivTeamsStore.lokomotivTeamsIds ||
          !lokomotivTeamsStore.lokomotivTeamsIds.length ||
          !protocol ||
          !game
        ) {
          return;
        }

        setIsLoading(true);

        let lokoPlayers: PlayerDto[];

        try {
          const playersResp = await listPlayers();
          lokoPlayers = playersResp?.data;
        } catch (error) {
          console.error(error);

          lokoPlayers = [];
        }

        const teamAStats = mapPlayersToReadable(
          protocol.GameSummary?.PlayerStatsList.find(
            (item) => item['@_teamId'] === protocol.GameSummary['@_homeId']
          )?.PlayerStats || []
        );
        const teamBStats = mapPlayersToReadable(
          protocol.GameSummary?.PlayerStatsList.find(
            (item) => item['@_teamId'] === protocol.GameSummary['@_visitorId']
          )?.PlayerStats || []
        );

        teamAStats.forEach((player) => {
          player.idlocal = lokoPlayers.find(
            (item) => item.attributes.id_league_web === player.id
          )?.id;
        });
        teamBStats.forEach((player) => {
          player.idlocal = lokoPlayers.find(
            (item) => item.attributes.id_league_web === player.id
          )?.id;
        });

        const teams = [
          {
            khlId: protocol.GameSummary && protocol.GameSummary['@_teama'],
            inGameId: protocol.GameSummary && protocol.GameSummary['@_homeId'],
            name: game.attributes?.team_1?.data?.attributes?.name,
            logo: game.attributes?.team_1?.data?.attributes?.logo?.data
              ?.attributes.url,
            city: game.attributes?.team_1?.data?.attributes?.city,
            score: protocol.GameSummary && protocol.GameSummary['@_score'],
            playerStats: teamAStats,
          },
          {
            khlId: protocol.GameSummary && protocol.GameSummary['@_teamb'],
            inGameId:
              protocol.GameSummary && protocol.GameSummary['@_visitorId'],
            name: game.attributes?.team_2?.data?.attributes?.name,
            logo: game.attributes?.team_2?.data?.attributes?.logo?.data
              ?.attributes.url,
            city: game.attributes?.team_2?.data?.attributes?.city,
            score:
              protocol.GameSummary &&
              protocol.GameSummary['@_score']?.split('')?.reverse()?.join(''),
            playerStats: teamBStats,
          },
        ];

        setTeams(teams);

        setActiveTab(
          teams.findIndex((team) => {
            return lokomotivTeamsStore.lokomotivTeamsIds.includes(
              Number(team.khlId)
            );
          })
        );

        setIsLoading(false);
      };

      loadData();
    }, [game, protocol, lokomotivTeamsStore.lokomotivTeamsIds]);

    const renderPlayerTables = (team: ProtocolTeamDatawithPlayerStats) => {
      if (!team.playerStats) {
        return <></>;
      }

      const forwards = team.playerStats.filter((item) => item.position === 'н');
      const goalkeepers = team.playerStats.filter(
        (item) => item.position === 'в'
      );
      const defenders = team.playerStats.filter(
        (item) => item.position === 'з'
      );

      return [forwards, defenders, goalkeepers].map((item, index) => (
        <table className={styles.table} key={index}>
          <tbody>
            <tr>
              <td>
                <PlayerStatsTable
                  playerStats={item}
                  role={
                    index === 0
                      ? 'Нападающие'
                      : index === 1
                      ? 'Защитники'
                      : 'Вратари'
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      ));
    };

    const getReadebleName = (name: string) => {
      if (name?.includes('.')) {
        return name.split('.')[1];
      } else {
        return name;
      }
    };

    const swapNameSurname = (name: string) => {
      const splitName = name?.trim().split(' ');
      if (splitName?.length === 2) {
        return `${splitName[1]} ${splitName[0]}`;
      }
      return name;
    };

    return (
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.date}>
              {getDateWithDayOfWeekCommon(game.attributes.date)}
            </div>
            {teams && (
              <div className={styles.teamStatsWrapper}>
                <TeamStatsTable protocol={protocol} teamsData={teams} />
              </div>
            )}

            <div className={styles.teamTabs}>
              <button
                onClick={() => setActiveTab(0)}
                className={classNames(styles.teamTab, {
                  [styles.teamTabActive]: activeTab === 0,
                })}
              >
                {teams && teams[0].name}
              </button>
              <button
                onClick={() => setActiveTab(1)}
                className={classNames(styles.teamTab, {
                  [styles.teamTabActive]: activeTab === 1,
                })}
              >
                {teams && teams[1].name}
              </button>
            </div>
            <div
              key={teams && teams[activeTab].inGameId}
              className={styles.teamTable}
            >
              <div className={styles.teamHeader}>
                <div className={styles.teamName}>
                  {teams && teams[activeTab].name}
                </div>
                <div className={styles.teamCity}>
                  {teams && teams[activeTab].city}
                </div>
              </div>
              <div>{teams && renderPlayerTables(teams[activeTab])}</div>
            </div>

            <div className={styles.additions}>
              <div className={styles.mainHeader}>
                <span>Дополнительная информация о матче</span>
              </div>
              <div>
                <div className={styles.header}>Судейская бригада</div>
                <div className={styles.rowWrapper}>
                  <div className={styles.title}>Рефери</div>
                  <div className={styles.text}>{`${
                    swapNameSurname(
                      getReadebleName(
                        protocol.GameSummary && protocol.GameSummary['@_refm1']
                      )
                    ) || ''
                  }${
                    protocol.GameSummary && protocol.GameSummary['@_refm1']
                      ? ', '
                      : ''
                  }${
                    swapNameSurname(
                      getReadebleName(
                        protocol.GameSummary && protocol.GameSummary['@_refm2']
                      )
                    ) || ''
                  }`}</div>

                  <div className={styles.title}>Лайнсмены</div>
                  <div className={styles.text}>{`${
                    swapNameSurname(
                      getReadebleName(
                        protocol.GameSummary && protocol.GameSummary['@_refl1']
                      )
                    ) || ''
                  }${
                    protocol.GameSummary && protocol.GameSummary['@_refl1']
                      ? ', '
                      : ''
                  }${
                    swapNameSurname(
                      getReadebleName(
                        protocol.GameSummary && protocol.GameSummary['@_refl2']
                      )
                    ) || ''
                  }`}</div>
                </div>
              </div>
              {teams && (
                <div>
                  <div className={styles.header}>Главные тренеры</div>
                  <div className={styles.rowWrapper}>
                    <div className={styles.title}>{teams[0].name}</div>
                    <div className={styles.text}>
                      {protocol.GameSummary && protocol.GameSummary['@_trainA']}
                    </div>

                    <div className={styles.title}>{teams[1].name}</div>
                    <div className={styles.text}>
                      {protocol.GameSummary && protocol.GameSummary['@_trainB']}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
);
export default GameStatsTable;
