import { ReactElement, RefObject, lazy, useEffect, useState } from 'react';
import GameWidgetHPBlock from '../GameWidgetHPBlock/GameWidgetHPBlock';
import ScheduleHPBlock from '../ScheduleHPBlock/ScheduleHPBlock';
import LeadersWidget from './LeadersWidget/LeadersWidget';
import InfoWrapper from '../Wrappers/InfoWrapper/InfoWrapper';
import TopStory from '../TopStory/TopStory';
import styles from './HomePage.module.scss';
import MainNews from './MainNews/MainNews';
import HeaderPartners from './HeaderPartners/HeaderPartners';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import ShowOn from '../core/adaptivity/ShowOn';
import Flex from '../core/layout/Flex';
import Column from '../core/layout/Column';
import Row from '../core/layout/Row';
import MainNewsMobileText from './MainNewsMobileText/MainNewsMobileText';
import MainNewsMobileImage from './MainNewsMobileImage/MainNewsMobileImage';
import Container from 'components/common/Container/Container';
import { useStore } from 'store/store';
import SidePartners from './SidePartnes/SidePartners';
import AdvertisingWidget from 'components/AdvertisingWidget/AdvertisingWidget';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import lokoApi from 'model/api/lokoApi';
import Article from 'model/entity/loko/type/Article';
import { WidgetsPrip } from 'types/Types';
import { getWidgetsPrip } from 'api/api';

const ShopWidget = lazy(() => import('./ShopWidget/ShopWidget'));
const Standings = lazy(() => import('./Standings/Standings'));
const SocialNetNewsCompactWidget = lazy(
  () => import('./SocialNetNewsCompactWidget/SocialNetNewsCompactWidget')
);
const LokoTVWidget = lazy(() => import('./LokoTVWidget/LokoTVWidget'));
const PhotoGallery = lazy(() => import('./PhotoGallery/PhotoGallery'));
const SweaterBanner = lazy(() => import('../SweaterBanner/SweaterBanner'));
const LokomaniaBanner = lazy(
  () => import('../LokomaniaBanner/LokomaniaBanner')
);

type HomePageType = {
  footerRef: RefObject<HTMLDivElement>;
};

function HomePage({ footerRef }: HomePageType): ReactElement {
  const [articles, setArticles] = useState<Array<Article>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [widgetsPrip, setWidgetsPrip] = useState<WidgetsPrip>();

  const { currentSeasonStore, sideMenuStore } = useStore();
  const isPrip = currentSeasonStore.currentSeason?.men?.slice(-4) === 'prip';
  const windowInnerWidth = document.documentElement.clientWidth;
  const isLargeTablet = windowInnerWidth > 1000;

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await lokoApi.article.getTopStories(4);
        setArticles(resp?.data);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const itemsData = await getWidgetsPrip();
        setWidgetsPrip(itemsData?.data);
      } catch (e: any) {
        console.error(e?.message);
      }
    };

    fetchItems();
  }, []);

  const renderDesktopAndTablet = () => (
    <>
      <AdvertisingWidget footerRef={footerRef} />
      <Container>
        {isLoading ? (
          <div style={{ height: '400px', padding: '1px' }}>
            <Spinner />
          </div>
        ) : isError || !articles ? (
          <div style={{ height: '400px', padding: '1px' }}>
            <ErrorWidget.Error isOnDarkBackground />
          </div>
        ) : (
          <MainNews article={articles[0]} />
        )}
      </Container>

      <InfoWrapper innerPaddingTop={'20px'}>
        <div style={{ margin: isLargeTablet ? '0 20px' : '0 16px' }}>
          {isLoading ? (
            <Spinner />
          ) : isError || !articles ? (
            <ErrorWidget.Error />
          ) : (
            <>
              <ShowOn largeDesktop smallDesktop>
                <TopStory articles={articles} count={3} />
              </ShowOn>
              <ShowOn largeTablet smallTablet>
                <TopStory articles={articles} count={2} />
              </ShowOn>
            </>
          )}
          <HeaderPartners />
          {isLoading ? (
            <Spinner />
          ) : isPrip ? (
            <>
              <ShowOn largeDesktop smallDesktop>
                <Row gap='20px'>
                  <Flex weight={6}>
                    <SocialNetNewsCompactWidget isHomePage />
                  </Flex>
                  {widgetsPrip?.attributes?.games_widget ? (
                    <Flex weight={4}>
                      <div id={'match-column'}>
                        <GameWidgetHPBlock />
                      </div>
                    </Flex>
                  ) : (
                    sideMenuStore.sideMenuPartners.length > 0 && (
                      <Flex weight={5}>
                        <SidePartners />
                      </Flex>
                    )
                  )}
                </Row>
              </ShowOn>
              <ShowOn smallTablet largeTablet>
                <Column gap='30px'>
                  <Flex weight={6}>
                    <SocialNetNewsCompactWidget isHomePage />
                  </Flex>
                  {widgetsPrip?.attributes?.games_widget ? (
                    <Column gap='30px'>
                      <GameWidgetHPBlock />
                    </Column>
                  ) : (
                    <Flex weight={5}>
                      <SidePartners />
                    </Flex>
                  )}
                </Column>
              </ShowOn>
            </>
          ) : (
            <div className={styles.twoWidgetsWrapper}>
              <ShowOn largeDesktop smallDesktop>
                <Row gap='30px'>
                  <Flex weight={6}>
                    <LeadersWidget />
                  </Flex>
                  <Flex weight={4}>
                    <div id={'match-column'}>
                      <GameWidgetHPBlock />
                    </div>
                  </Flex>
                </Row>
              </ShowOn>
              <ShowOn largeTablet smallTablet>
                <Column gap='30px'>
                  <LeadersWidget />
                  <GameWidgetHPBlock />
                </Column>
              </ShowOn>
            </div>
          )}
        </div>
        <div
          style={{
            height: '338px',
            backgroundColor: '#C8102E',
            marginTop: '30px',
          }}
        >
          <ScheduleHPBlock />
        </div>
        <div style={{ margin: isLargeTablet ? '0 20px' : '0 16px' }}>
          <ShopWidget />
          <div
            style={{
              marginTop: !isPrip ? '30px' : '0',
            }}
          >
            <ShowOn largeDesktop smallDesktop>
              {isLoading ? (
                <Spinner />
              ) : (
                !isPrip && (
                  <Row gap='20px'>
                    <Flex weight={6}>
                      <Standings />
                    </Flex>
                    <Flex weight={4}>
                      <SocialNetNewsCompactWidget isHomePage />
                    </Flex>
                  </Row>
                )
              )}
            </ShowOn>
            <ShowOn largeTablet smallTablet>
              <Column gap='20px'>
                {isLoading ? (
                  <Spinner />
                ) : (
                  !isPrip && (
                    <Column gap='30px'>
                      <Standings />
                      <SocialNetNewsCompactWidget isHomePage />
                    </Column>
                  )
                )}
              </Column>
            </ShowOn>
          </div>
          <LokoTVWidget />
          <ShowOn largeDesktop smallDesktop>
            <PhotoGallery />
          </ShowOn>
          <ShowOn largeTablet smallTablet>
            <PhotoGallery itemsCount={2} />
          </ShowOn>
        </div>
        <SweaterBanner />
        <LokomaniaBanner />
      </InfoWrapper>
    </>
  );

  const renderMobile = () => (
    <>
      <AdvertisingWidget footerRef={footerRef} />
      {isLoading ? (
        <Spinner />
      ) : isError || !articles ? (
        <ErrorWidget.Error isOnDarkBackground />
      ) : (
        <ShowOn smallMobile largeMobile>
          <MainNewsMobileImage article={articles[0]} />
        </ShowOn>
      )}
      <InfoWrapper innerPaddingTop={'20px'} isWhiteBackground={true}>
        {isLoading ? (
          <Spinner />
        ) : isError || !articles ? (
          <ErrorWidget.Error />
        ) : (
          <ShowOn smallMobile largeMobile>
            <MainNewsMobileText article={articles[0]} />
          </ShowOn>
        )}
        <div style={{ margin: '0 16px' }}>
          {isLoading ? (
            <Spinner />
          ) : isError ? (
            <ErrorWidget.Error />
          ) : (
            <ShowOn largeMobile smallMobile>
              <TopStory articles={articles} isHomePageMobileVersion />
            </ShowOn>
          )}
        </div>
        <HeaderPartners />
        <div style={{ margin: '0 16px' }}>
          {isLoading ? (
            <Spinner />
          ) : !isPrip ? (
            <div className={styles.twoWidgetsWrapper}>
              <Column gap='30px'>
                <LeadersWidget columnStyle={true} />
                <GameWidgetHPBlock />
              </Column>
            </div>
          ) : (
            <Column gap='30px'>
              <SocialNetNewsCompactWidget isHomePage />
              {widgetsPrip?.attributes?.games_widget ? (
                <GameWidgetHPBlock />
              ) : (
                <SidePartners />
              )}
            </Column>
          )}
        </div>
        <div
          style={{
            height: '320px',
            backgroundColor: '#C8102E',
            marginTop: '30px',
          }}
        >
          <ScheduleHPBlock />
        </div>
        <ShopWidget />
        <ShowOn largeMobile smallMobile>
          {isLoading ? (
            <Spinner />
          ) : (
            !isPrip && (
              <Column gap='30px'>
                <Standings />
                <div style={{ margin: '0 16px' }}>
                  <SocialNetNewsCompactWidget isHomePage />
                </div>
              </Column>
            )
          )}
        </ShowOn>
        <div style={{ margin: '0 16px' }}>
          <LokoTVWidget />
          <PhotoGallery columnStyle itemsCount={4} isHomepage />
        </div>
        <SweaterBanner />
        <LokomaniaBanner />
      </InfoWrapper>
    </>
  );

  return (
    <>
      <ImageBgPageWrapper>
        <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
          {renderDesktopAndTablet()}
        </ShowOn>
        <ShowOn largeMobile smallMobile>
          {renderMobile()}
        </ShowOn>
      </ImageBgPageWrapper>
    </>
  );
}

export default HomePage;
