import { ReactElement, useEffect, useState } from 'react';
import styles from './GoalStatsTable.module.scss';
import {
  GameProtocolDto,
  GameWithLogo,
  Highlight,
  PlayerWithRelations,
} from '../../../../../types/Types';
import { getPlayerByIdLeague } from '../../../../../api/api';
import Spinner from '../../../../Spinner/Spinner';
import ShootoutsTable from '../ShootoutsTable/ShootoutsTable';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';

type GoalData = {
  id: number;
  period: string;
  time: string;
  score: string;
  pmg: string;
  playerNumber: string;
  playerName: string;
  playerId?: number;
  assist1: string;
  assist2: string;
  playerImg: string | undefined;
  team: {
    khlId: string;
    name: string;
    logo: string;
  };
};

type GoalStatsTableProps = {
  protocol: GameProtocolDto;
  game: GameWithLogo;
  goalHighlights: Highlight[];
  summaryHighlights: Highlight[];
  setSelectedHighlight: (highlight: Highlight) => void;
};

const GoalStatsTable = observer(
  ({
    protocol,
    game,
    goalHighlights,
    summaryHighlights,
    setSelectedHighlight,
  }: GoalStatsTableProps): ReactElement => {
    const [goals, setGoals] = useState<GoalData[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { lokomotivTeamsStore } = useStore();
    const windowInnerWidth = document.documentElement.clientWidth;
    const isSmallMobile = windowInnerWidth < 360;

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        const teams = {
          [protocol.GameSummary['@_homeId']]: {
            khlId: protocol.GameSummary['@_teama'],
            name: game.attributes.team_1.data.attributes.name,
            logo: game.attributes.team_1.data.attributes.logo.data.attributes
              .url,
          },
          [protocol.GameSummary['@_visitorId']]: {
            khlId: protocol.GameSummary['@_teamb'],
            name: game.attributes.team_2.data.attributes.name,
            logo: game.attributes.team_2.data.attributes.logo.data.attributes
              .url,
          },
        };
        const khlGoals = Array.isArray(protocol.GameSummary.Goals.Goal)
          ? protocol.GameSummary.Goals.Goal
          : [protocol.GameSummary.Goals.Goal];
        const goalsData: GoalData[] = [];

        for (let goalIndex = 0; goalIndex < khlGoals.length; goalIndex++) {
          const goal = khlGoals[goalIndex];
          let player: PlayerWithRelations | null = null;
          const playerTeamId = teams[goal['@_teamId']].khlId;

          if (
            lokomotivTeamsStore.lokomotivTeamsIds.includes(
              parseInt(playerTeamId)
            )
          ) {
            try {
              const response = await getPlayerByIdLeague(
                Number(goal['@_scorer_id'])
              );

              player = response?.data[0];
            } catch (e) {
              // player is used to get photo only. if not found, default is used.
              // setting even partial error state here is not necessary
              console.error(e);
            }
          }

          const data: GoalData = {
            id: goalIndex,
            period: goal['@_per'],
            time: goal['@_time'],
            score: goal['@_score'],
            pmg: goal['@_pmg'],
            playerNumber: goal['@_scorer'].split('.')[0],
            playerName: getScorerName(goal['@_scorer']),
            playerId: player?.id,
            assist1: getAssistantName(goal['@_assist_1']),
            assist2: getAssistantName(goal['@_assist_2']),
            playerImg: player?.attributes.photo?.data?.attributes.url,
            team: teams[goal['@_teamId']],
          };

          goalsData.push(data);
        }

        setGoals(goalsData);
        setIsLoading(false);
      };

      fetchData();
    }, [game, protocol]);

    const getScorerName = (scorer: string): string => {
      const [number, fullNameWithStats] = scorer.split('.');
      const [surname, nameWithStats] = fullNameWithStats?.split(' ');
      const nameShort = nameWithStats.split('')[0];

      let stats = '';

      if (scorer.indexOf('(') > -1) {
        stats = scorer.slice(scorer.indexOf('(') + 1, scorer.indexOf(')'));
      }

      return `${number}. ${nameShort}. ${surname} ${
        stats ? ` (${stats})` : ''
      }`;
    };

    const getAssistantName = (assistant: string): string => {
      if (assistant) {
        const [, fullNameWithStats] = assistant?.split('.');
        const [surname] = fullNameWithStats?.split(' ');

        let stats = '';

        if (assistant?.indexOf('(') > -1) {
          stats = assistant?.slice(
            assistant?.indexOf('(') + 1,
            assistant?.indexOf(')')
          );
        }

        return `${surname} ${stats ? ` (${stats})` : ''}`;
      } else {
        return '';
      }
    };

    const redirectToPlayer = (id?: number): void => {
      if (id) {
        window.open(`/player/${id}`);
      }
    };

    const renderPeriodData = (period: number, isShootouts: boolean) => {
      let periodGoals: GoalData[] = [];

      if (goals) {
        if (isShootouts) {
          periodGoals = goals.filter((item) =>
            period === 5
              ? item.period === '4'
              : item.period === period.toString()
          );
        } else {
          periodGoals = goals.filter((item) =>
            period === 4
              ? parseInt(item.period) >= 4
              : item.period === period.toString()
          );
        }
      }

      if (period === 4 && !periodGoals?.length) {
        return null;
      }

      const periodTitle =
        period === 4
          ? 'Овертайм'
          : period === 5
          ? 'Победный буллит'
          : `${period}-й период`;

      if (period === 4 && isShootouts) {
        return null;
      }

      return (
        <div key={period}>
          <div className={styles.period}>
            <span>{periodTitle}</span>
            {summaryHighlights[period - 1] && (
              <span
                className={styles.periodSummary}
                onClick={() =>
                  setSelectedHighlight(summaryHighlights[period - 1])
                }
              >
                ▶ Лучшие моменты
              </span>
            )}
          </div>

          {isLoading ? (
            <Spinner className={styles.spinner} />
          ) : periodGoals && periodGoals.length ? (
            periodGoals.map((item) => (
              <div className={styles.row} key={item.time}>
                <div className={styles.playerInfo}>
                  {isSmallMobile ? (
                    <></>
                  ) : (
                    <div className={styles.imageWrapper}>
                      <img
                        className={styles.image}
                        src={item.playerImg ? item.playerImg : item.team.logo}
                        alt=''
                      />
                    </div>
                  )}
                  <div className={styles.scorer}>
                    <div
                      className={classNames(styles.main, {
                        [styles.pointer]: !!item.playerId,
                      })}
                      onClick={() => redirectToPlayer(item.playerId)}
                    >
                      {item.playerName}
                    </div>
                    <div className={styles.assist}>
                      {item.assist1 && item.assist2
                        ? `${item.assist1}, ${item.assist2}`
                        : item.assist1
                        ? item.assist1
                        : item.assist2}
                    </div>
                  </div>
                </div>
                <div className={styles.scoreInfo}>
                  <div className={styles.scoreWrapper}>
                    <div className={styles.time}>{item.time}</div>
                    <div
                      className={styles.score}
                    >{`${game.attributes.team_1.data.attributes.name.slice(
                      0,
                      3
                    )} ${
                      item.score.split(':')[0]
                    }, ${game.attributes.team_2.data.attributes.name.slice(
                      0,
                      3
                    )} ${item.score.split(':')[1]}`}</div>
                    {item.pmg && parseInt(item.pmg) > 0 && (
                      <div className={styles.pmg}>БОЛ</div>
                    )}
                    {item.pmg && parseInt(item.pmg) < 0 && (
                      <div className={styles.pmg}>МЕН</div>
                    )}
                  </div>
                  {goalHighlights[item.id] && (
                    <span
                      className={styles.goalHighlight}
                      onClick={() =>
                        setSelectedHighlight(goalHighlights[item.id])
                      }
                    >
                      ▶ Смотреть
                    </span>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className={styles.withoutGoals}>Без шайб</div>
          )}
        </div>
      );
    };

    const render = () => {
      const isShootouts = !!protocol?.GameSummary?.Shootout;
      const periods = isShootouts ? [1, 2, 3, 4, 5] : [1, 2, 3, 4];
      return periods.map((item) => renderPeriodData(item, isShootouts));
    };

    return (
      <div>
        <div className={styles.header}>Голевая статистика</div>
        {render()}
        <ShootoutsTable game={game} protocol={protocol} />
      </div>
    );
  }
);

export default GoalStatsTable;
